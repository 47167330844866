import React, { useState } from "react";
import { Row, Col, Button, Dropdown } from "react-bootstrap";
import AddToCalendarHOC, { SHARE_SITES } from "react-add-to-calendar-hoc";
import moment from "moment";
import { useTools } from "context/toolContext";
import { useJob } from "context/jobContext";
import * as JobApi from "../../../api/job.api";
import mixpanel from "mixpanel-browser";
import { checkPendingStatus } from "utils";
import { JOB_STATUS } from "../../../constants/index";
import { Modal } from "antd";
import { POST_JOB_TITLE } from "constants/expandableJobContants";
import {
  ExtraCheckBeforePostJobModal,
  InProgressJobModal,
} from "./DashboardModals";
import { useHistory } from "react-router";
import { useSocket } from "context/socketContext";
import ScheduleJobCancelModal from "pages/Dashboard/components/ScheduleJobCancelModal";
import DeleteDraftJobModal from 'pages/Dashboard/components/DeleteDraftJobModal';
import { isMobile,isTablet } from "react-device-detect";
import { useShabbat } from 'context/shabbatContext';

const CustomerJobsActiveBtn = ({
  job,
  btnsToShowList,
  userType,
  user,
  decline_job_by_technician,
  pushToMeeting,
}) => {
  const {
    postJobBtn = false,
    postAgainJobBtn = false,
    postAgainWithSameTechBtn = false,
    addToCalendarDropdownBtn = false,
    cancelJobBtn = false,
    joinJobBtn = false,
    startCallBtn = false,
    deleteDraftJobBtn = false,
  } = btnsToShowList;
  const duration = 2;
  const AddToCalendarDropdown = AddToCalendarHOC(Button, Dropdown);
  const {
    setJobId,
    setTypeForDetails,
    setStepDeciderDashboard,
    setJobFlowStep,
    jobFlowsDescriptions,
  } = useTools();
  const { fetchJob } = useJob();
  const history = useHistory();
  const { socket } = useSocket();

  const [scheduleMsg, setScheduleMsg] = useState(false);
  const [lastPendingSoftware, setLastPendingSoftware] = useState("");
  const [inProgressMessage, setInProgressMessage] = useState("");
  const [pendingJobModal, setPendingJobModal] = useState(false);
  const [customerConfirm, setCustomerConfirm] = useState(false);
  const [customerConfirmDraftJob, setCustomerConfirmDraftJob] = useState(false);
  const [customerConfirmSameTech, setCustomerConfirmSameTech] = useState(false);

  const [isCancelModal, setIsCancelModal] = useState(false);
  const [isDeleteDraftJobModal, setIsDeleteDraftJobModal] = useState(false);
  const { isShabbat } = useShabbat();
  const message = scheduleMsg ? (
    <span className="div-font" style={{ fontSize: 20, paddingTop: "40px" }}>
      One of your previous jobs of{" "}
      <b style={{ fontWeight: "bold" }}>{lastPendingSoftware}</b> is already
      scheduled with a technician. Are you sure you want to create a new job
      post?if yes, then your previous job will be{" "}
      <b style={{ fontWeight: "bold" }}>Cancelled</b>
    </span>
  ) : (
    <span className="div-font" style={{ fontSize: 20, paddingTop: "40px" }}>
      We are still looking for a technician for your existing job of{" "}
      <b style={{ fontWeight: "bold" }}>{lastPendingSoftware}</b>. Are you sure
      you want to create a new job post? if yes, then your previous job will be{" "}
      <b style={{ fontWeight: "bold" }}>Cancelled</b>
    </span>
  );

  const InprogressJobMsg = pendingJobModal ? (
    <span className="div-font" style={{ fontSize: 20, paddingTop: "40px" }}>
      {inProgressMessage}
    </span>
  ) : (
    <span></span>
  );

  // When user clicks on the Details button then this will open Job details of that particular job
  const openJobDetails = async (e) => {
    e.stopPropagation();
    const jobid = job?.id;
    await setJobId(jobid);
    await fetchJob(jobid);
    await JobApi.updateJob(jobid, { tech_message_dashbord: false });
    let jobResponse = await JobApi.retrieveJob(jobid);
    setTypeForDetails("details");
    const scheduleAccepted = jobResponse.schedule_accepted == false;
    const techDeclinedJob =
      jobResponse?.tech_declined_ids.includes(user?.technician?.id) == false;
    const isTech = user && user?.userType == "technician";
    if (scheduleAccepted && isTech && techDeclinedJob) {
      setTypeForDetails("apply");
    }
    if (userType === "technician") {
      mixpanel.identify(user.email);
      mixpanel.track(
        "Technician  - Click on Job details from dashboard page ",
        { JobId: jobid }
      );
    } else {
      mixpanel.identify(user.email);
      mixpanel.track("Customer -  Click on Job details from dashboard page", {
        JobId: jobid,
      });
    }
    setStepDeciderDashboard(6);
  };

  /**
   * Function will check if status of job is draft then redirect user to Job summary page  .
   * @author : Mritunjay
   **/
  const postDraftJob = async (e) => {
    e.stopPropagation();
    try {
      if (user && userType === "customer" && user.customer) {
        const jobid = job?.id;
        let jobResponse = await JobApi.retrieveJob(jobid);
        // let pendingJobs = await checkPendingStatus(user);
        // if (pendingJobs.schedule_accepted) {
        //   setScheduleMsg(true);
        // }

          Modal.confirm({
            title: POST_JOB_TITLE,
            okText: "Yes",
            cancelText: "No",
            className: "app-confirm-modal",
            onOk: async () => {
              if (jobResponse.status === "Draft") {
                if (isMobile || isTablet) {
                  if(jobResponse?.post_again_reference_technician){
                    history.push(`/post-job?technicianId=${jobResponse.post_again_reference_technician}`);
                  }else{
                    history.push("/post-job");
                  }
                } else {
                  if (jobResponse.post_again_reference_technician) {
                    window.location.href = `/customer/profile-setup?page=job-summary&jobId=${jobResponse.id}&technicianId=${jobResponse.post_again_reference_technician}`;
                  } else {
                    window.location.href = `/customer/profile-setup?page=job-summary&jobId=${jobResponse.id}`;
                  }
                }
              }
            },
          });
        
      }
    } catch (e) {
      console.log("Error in checkPendingJobs", e);
    }
  };

  const postAgainFunction = async (postType) => {
    if (user && userType === "customer" && user.customer) {
      try {
        const latestJob = await JobApi.latestpendingJobs({
          customer: user.customer.id,
        });

        if (latestJob.total_pending_jobs > 0) {
          const { last_pending_job } = latestJob;

          if (
            last_pending_job.status === JOB_STATUS.PENDING ||
            last_pending_job.status === JOB_STATUS.WAITING ||
            last_pending_job.status === JOB_STATUS.SCHEDULED
          ) {
            try {
              await JobApi.updateJob(last_pending_job.id, {
                status: "Declined",
              });

              let jobId = job?.id;
              let technicianId;

              switch (postType) {
                case "standard":
                  mixpanel.track("Customer - Post again from dashboard", {
                    JobId: jobId,
                  });
                  setJobFlowStep(jobFlowsDescriptions["jobDetailView"]);
                  history.push(
                    `/customer/start-profile-setup?jobId=${jobId}&repost=true`
                  );
                  break;

                case "sameTech":
                  const retrievedJob = await JobApi.retrieveJob(jobId);
                  technicianId = retrievedJob?.technician?.user?.id;
                  history.push(
                    `/customer/profile-setup?jobId=${jobId}&repost=true&technicianId=${technicianId}`
                  );
                  break;

                case "draft":
                  const jobResponse = await JobApi.retrieveJob(jobId);

                  if (jobResponse.status === "Draft") {
                    if (jobResponse?.post_again_reference_technician) {
                      window.location.href = `/customer/profile-setup?page=job-summary&jobId=${jobResponse?.id}&technicianId=${jobResponse.post_again_reference_technician}`;
                    } else {
                      window.location.href = `/customer/profile-setup?page=job-summary&jobId=${jobResponse?.id}`;
                    }
                  }
                  break;

                default:
                  break;
              }
            } catch (error) {
              console.error(error);
            }
          }
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const closePendingModal = () => {
    setCustomerConfirm(false);
    setCustomerConfirmSameTech(false);
    setCustomerConfirmDraftJob(false);
    setPendingJobModal(false);
  };

  const postAgainJob = async (e) => {
    e.stopPropagation();
    try {
      if (user && userType === "customer" && user?.customer) {
        // let pendingJobs = await checkPendingStatus(user);
        // // //if (pendingJobs.schedule_accepted) {
        // //   setScheduleMsg(true);
        // // }

        // if (pendingJobs.success) {
        //   setLastPendingSoftware(pendingJobs.name);
        //   if (
        //     pendingJobs.status === "Inprogress" ||
        //     pendingJobs.status === "Accepted"
        //   ) {
        //     setPendingJobModal(true);
        //     setInProgressMessage(
        //       <span
        //         className="div-font"
        //         style={{ fontSize: 20, paddingTop: "40px" }}
        //       >
        //         One of your job of{" "}
        //         <b style={{ fontWeight: "bold" }}>{pendingJobs.name}</b> is
        //         already{" "}
        //         <b style={{ fontWeight: "bold" }}>{pendingJobs.status}</b> with
        //         a technician. Please complete that job first then post a new
        //         job.
        //       </span>
        //     );
        //   } else {
        //     setCustomerConfirm(true);
        //   }
        // } else {
          Modal.confirm({
            title: "Are you sure you want to post this job again?",
            okText: "Yes",
            cancelText: "No",
            className: "app-confirm-modal",
            okButtonProps: {
              id: "post-again-yes-button", // Set the id for the "Yes" button
            },
            onOk() {
              const jobid = job?.id;
              mixpanel.identify(user.email);
              mixpanel.track("Customer - Post again from dashboard", {
                JobId: jobid,
              });
              setJobFlowStep(jobFlowsDescriptions["jobDetailView"]);
              if (isMobile || isTablet) {
                history.push("/post-job");
              } else {
                history.push(
                  `/customer/start-profile-setup?jobId=${jobid}&repost=true`
                );
              }
            },
          });
        //}
      }
    } catch (e) {
      console.log("Error in checkPendingJobs", e);
    }
  };

  const CancelTheJob = (e) => {
    setIsCancelModal(true);
  };

  const DeleteDraftJob = () => {
    setIsDeleteDraftJobModal(true);
  }

  const postAgainJobWithSameTech = async (e) => {
    e.stopPropagation();
    try {
      if (user && userType === "customer" && user?.customer) {
        let jobId = job?.id;
        let retrievedJob = await JobApi.retrieveJob(jobId);
        // let pendingJobs = await checkPendingStatus(user);
        // if (pendingJobs.schedule_accepted) {
        //   setScheduleMsg(true);
        // }
        // if (pendingJobs.success) {
        //   setLastPendingSoftware(pendingJobs.name);

        //   if (
        //     pendingJobs.status === "Inprogress" ||
        //     pendingJobs.status === "Accepted"
        //   ) {
        //     setPendingJobModal(true);
        //     setInProgressMessage(
        //       <span
        //         className="div-font"
        //         style={{ fontSize: 20, paddingTop: "40px" }}
        //       >
        //         One of your job of{" "}
        //         <b style={{ fontWeight: "bold" }}>{pendingJobs.name}</b> is
        //         already{" "}
        //         <b style={{ fontWeight: "bold" }}>{pendingJobs.status}</b> with
        //         a technician. Please complete that job first then post a new
        //         job.
        //       </span>
        //     );
        //   } else {
        //     setCustomerConfirmSameTech(true);
        //   }
        // } else {
          Modal.confirm({
            title: "Are you sure you want to post this job again?",
            okText: "Yes",
            cancelText: "No",
            className: "app-confirm-modal",
            okButtonProps: {
              id: "post-with-same-tech-confirm", // Set the id for the "Yes" button
            },
            onOk() {
              mixpanel.identify(user.email);
              if (isMobile || isTablet) {
                history.push(`/post-job?technicianId=${retrievedJob?.technician?.user?.id}`);
              } else {
                history.push(
                  `/customer/profile-setup?jobId=${jobId}&repost=true&technicianId=${retrievedJob?.technician?.user?.id}`
                );
              }
            },
          });
       // }
      }
    } catch (e) {
      console.log("Error in checkPendingJobs", e);
    }
  };

  return (
    <>
      <ExtraCheckBeforePostJobModal
        closePendingModal={closePendingModal}
        customerConfirm={customerConfirm}
        customerConfirmSameTech={customerConfirmSameTech}
        customerConfirmDraftJob={customerConfirmDraftJob}
        setCustomerConfirm={setCustomerConfirm}
        setCustomerConfirmSameTech={setCustomerConfirmSameTech}
        setCustomerConfirmDraftJob={setCustomerConfirmDraftJob}
        postAgainFunction={postAgainFunction}
        message={message}
      />
      <InProgressJobModal
        closePendingModal={closePendingModal}
        pendingJobModal={pendingJobModal}
        InprogressJobMsg={InprogressJobMsg}
      />

      <ScheduleJobCancelModal
        isCancelModal={isCancelModal}
        setIsCancelModal={setIsCancelModal}
        cancelJobId={job?.id}
        user={user}
        type={userType == "customer" ? "Customer" : "Technician"}
        job={job}
        decline_job_by_technician={decline_job_by_technician}
        setcurrentStep={setStepDeciderDashboard}
      />

      <DeleteDraftJobModal
        isDeleteDraftJobModal={isDeleteDraftJobModal}
        setIsDeleteDraftJobModal={setIsDeleteDraftJobModal}
        user={user}
        job={job}
      />

      {/* <JobCancelFrom
        isCancelModal={isCancelModal}
        setIsCancelModal={setIsCancelModal}
        cancelJobId={job?.id}
        user={user}
        type={userType == "customer" ? "Customer" : "Technician"}
        job={job}
        decline_job_by_technician={decline_job_by_technician}
        setcurrentStep={setStepDeciderDashboard}
      /> */}

      <Row key={job?.id}>
        <Col xs="12">
          <Button
            className="mb-2 btn app-btn"
            name={`${job?.id}`}
            title="Click to see job details."
            onClick={openJobDetails}
          >
            Details<span></span>
          </Button>

          {postJobBtn && (
            <Button
              className="mb-2 btn app-btn"
              name={`${job?.id}`}
              title="Please post job agian with valid card."
              onClick={postDraftJob}
              disabled={isShabbat}
            >
              Post<span></span>
            </Button>
          )}
          {postAgainJobBtn && (
            <Button
              className="mb-2 btn app-btn"
              name={`${job?.id}`}
              title="Post Again this job"
              onClick={postAgainJob}
              disabled={isShabbat}
            >
              Post Again<span></span>
            </Button>
          )}
          {postAgainWithSameTechBtn && (
            <Button
              className="mb-2 btn app-btn"
              name={`${job?.id}`}
              title="Post again with same technician"
              onClick={postAgainJobWithSameTech}
              disabled={isShabbat}
            >
              Post again with same technician<span></span>
            </Button>
          )}

          {addToCalendarDropdownBtn && (
            <React.Fragment key="scheduled">
              <div className="addToCalendar-geeker mb-2">
                <AddToCalendarDropdown
                  event={{
                    title: "Geeker Job",
                    duration,
                    description: `Job description: <b>${job?.issueDescription}</b><br/>Job link : <a href="${process.env.REACT_APP_URL}/dashboard?scheduleJobId=${job.id}">${process.env.REACT_APP_URL}/dashboard?scheduleJobId=${job.id}</a>`,
                    startDatetime: moment
                      .utc(job.primarySchedule)
                      .format("YYYYMMDDTHHmmssZ"),
                    endDatetime: moment
                      .utc(
                        new Date(
                          new Date(job.primarySchedule).setHours(
                            new Date(job.primarySchedule).getHours() + 2
                          )
                        )
                      )
                      .format("YYYYMMDDTHHmmssZ"),
                  }}
                  buttonProps={{
                    className: "thisssssssssss",
                  }}
                  items={[SHARE_SITES.GOOGLE, SHARE_SITES.OUTLOOK]}
                />
              </div>
            </React.Fragment>
          )}
          {cancelJobBtn && (
            <Button
              className="mb-2 btn app-btn"
              name={`${job?.id}`}
              title="Cancel"
              onClick={CancelTheJob}
              disabled={isShabbat}
            >
              Cancel<span></span>
            </Button>
          )}
          {deleteDraftJobBtn && (
            <Button
              className="mb-2 btn app-btn"
              name={`${job?.id}`}
              title="Delete"
              onClick={DeleteDraftJob}
              style={{
                backgroundColor: "#9f0000",
              }}
            >
              Delete<span></span>
            </Button>
          )}
          {joinJobBtn && (
            <Button
              className="mb-2 btn app-btn"
              name={`${job?.id}`}
              title="Join"
              onClick={pushToMeeting}
            >
              Join<span></span>
            </Button>
          )}
          {startCallBtn && (
            <Button
              className="mb-2 btn app-btn"
              onClick={pushToMeeting}
              name={`${job?.id}`}
              title="Start Call"
            >
              Start Call<span></span>
            </Button>
          )}
        </Col>
      </Row>
    </>
  );
};

export default CustomerJobsActiveBtn;
