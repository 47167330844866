import CheckInCircle from "components/CheckInCircle";
import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { ColorRing } from "react-loader-spinner";
import HeadingText from "../Components/HeadingText";
import ProgressBar from "../Components/ProgressBar";
import SubHeadingText from "../Components/SubHeadeingText";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BasicButton from "components/common/Button/BasicButton";
import Testimony from "../Components/Testimony";
import AvgInfo from "../Components/AvgInfo";
import { useHistory, useLocation } from "react-router";
import { getAllPlans, buySubscription } from "../../../../api/subscription.api";
import Loader from "../../../../components/Loader";
import * as CustomerApi from "../../../../api/customers.api";
import * as UserApi from "../../../../api/users.api";
import CardLogo from "components/common/CardLogo";
import { Radio } from "antd";
import stripeSecureLogo from "../../../../assets/images/stripe-secure-logo.png";
import * as JobApi from "../../../../api/job.api";
import { faCheckCircle, faInfo, faTimes } from "@fortawesome/free-solid-svg-icons";
import {
  openNotificationWithIcon,
  isLiveUser,
  GAevent,
  fetchChatOrCreate,
  PushUserDataToGtm,
} from "../../../../utils";
import moment from "moment";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import {
  JOB_STATUS,
  SECRET_KEY,
  MESSAGES,
  JobTags,
  PREAUTH_BTN_TITLE,
  SUB_BTN_TITLE,
} from "../../../../constants";
import { faCreditCard } from "@fortawesome/free-solid-svg-icons";
import mixpanel from "mixpanel-browser";
import * as PromoApi from "../../../../api/promo.api";
import * as PublicApi from "../../../../api/public.api";
import { useJob } from "../../../../context/jobContext";
import { useSocket } from "../../../../context/socketContext";
import { Modal } from "antd";
import { useAuth } from "context/authContext";
import { useNotifications } from "../../../../context/notificationContext";
import EditJobModal from "../../ProfileSetup/Components/EditJobModal";
import * as SoftwareApi from "../../../../api/software.api";
import * as PromocodeApi from "../../../../api/promoCode.api";
import * as StripeApi from "../../../../api/stripeAccount.api";
import * as AppliedCoupons from "../../../../api/appliedCoupons.api";
import * as JobCycleApi from "../../../../api/jobCycle.api";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import SignUpHeading from "../../../../components/common/SignUpHeading";
import ErrorIcon from "@mui/icons-material/Error";
import { ConsoleSqlOutlined } from "@ant-design/icons";
import QuestionMarkOutlinedIcon from '@mui/icons-material/QuestionMarkOutlined';
import Header from "../Components/Header";
import styled from "styled-components";
const firstFreePromoCode = localStorage.getItem("firstFreePromoCode");


const CompleteYourPurchase = ({
  user,
  setbusinessPlanStepNumber,
  jobFlowStepsObj,
  setShowSubscriptionPlanModal,
  setShowtwentyPercentModal,
  job,
  isScheduleJob,
  isFirsJob,
}) => {
  const history = useHistory();
  const location = useLocation();
  const [isHovering, setIsHovering] = useState(false);
  const { refetch, getGuestUser } = useAuth();
  const queryParams = new URLSearchParams(location.search);
  const planId = queryParams.get("planId") ? queryParams.get("planId") : false;
  const jobIdFromUrl = queryParams.get("jobId")
    ? queryParams.get("jobId")
    : false;
  const technicianId = queryParams.get("technicianId")
    ? queryParams.get("technicianId")
    : false;
  const elements = useElements();
  const stripe = useStripe();
  let liveUser;
  const chatMessage = queryParams.get("message") ? queryParams.get("message") : false;
  const [planData, setPlanData] = useState();
  const [planInfo, setPlanInfo] = useState();
  const [promocode, setPromocode] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [showCCForm, setShowCCForm] = useState(true);
  const [disableBtn, setDisableBtn] = useState(false);
  const partnerPromoCode = localStorage.getItem("partnerPromoCode");
  const [promoId, setPromoId] = useState(partnerPromoCode ? partnerPromoCode : "");
  const [couponId, setCouponId] = useState(
    queryParams.get("couponCode") ? queryParams.get("couponCode") : ""
  );
  const [showPromoCodeInputField, setShowPromoCodeInputField] = useState(false);
  const [formData, setFormData] = useState({ zip: "" });
  const [showSubscriptionPlanModalTime, setShowSubscriptionPlanModalTime] =
    useState(150000);
  const [discountModalShown, setDiscountModalShown] = useState(false);
  const [
    totalCustomerWithThisSubscrition,
    setTotalCustomerWithThisSubscrition,
  ] = useState(0);
  const { updateJob, setJob, fetchJob, fetchJobAsGuest } = useJob();
  const [jobData, setJobData] = useState(true);
  const { socket } = useSocket();
  const { createNotification, fetchNotifications } = useNotifications();
  const [softwareList, setSoftwareList] = useState([]);
  const [showEditJobModal, setShowEditJobModal] = useState(false);
  const [promoCodeApplied, setIsPromocodeApplied] = useState({});
  const [calculatedPrices, setCalculatedPrices] = useState({});
  const [customersDefaultCC, setCustomersDefaultCC] = useState();
  const [customersDefaultCCBrand, setCustomersDefaultCCBrand] = useState();
  const [changeCreditCard, setChangeCreditCard] = useState(false);
  const [customersAllCC, setCustomersAllCC] = useState();
  const [selectedCreditCard, setSelectedCreditCard] = useState();
  const [isJobSummaryUpdate, setIsJobSummaryUpdate] = useState(false);
  const [couponDiscountedPrice, setCouponDiscountedPrice] = useState();
  const [couponAlreadyUsed, setCouponAlreadyUsed] = useState(false);
  const [hasCardChanged, setHasCardChanged] = useState(false);
  const [cardDetailsFilled,setCardDetailsFilled]= useState({
    cardNumber : false,
    expiryDate : false,
    cvc : false,
    zip : false
  })
  const [isModalOpen, setIsModalOpen] = useState(false); 
  const hiddenSoftwares = localStorage.getItem("hiddenSoftwares");
  const [promoApplied, setPromoApplied] = useState(false);
  const [promoCodeValue, setPromoCodeValue] = useState("");


  // useEffect(() => {
  //   const updateJobAndRedirect = async () => {
  //     console.log("hello world");
  //     if (firstFreePromoCode) {
  //       await JobApi.updateJob(jobIdFromUrl, {
  //         status: 'Pending',
  //         isPartnerFirstJob: true,
  //       });
  //       window.location.href = `/customer/profile-setup?page=tech-search&jobId=${jobIdFromUrl}`;
  //     }
  //   };
  
  //   updateJobAndRedirect();
  // }, [firstFreePromoCode]);
  
  useEffect(() => {

    if (user) {
      if (user?.customer?.subscription) {
        openNotificationWithIcon("error", 
        "You are already subscribed to our service. Redirecting you to the dashbord shortly.");
        setTimeout(() => {
          window.location.href = "/";
        }, 3000);
      }
    }

  }, [user]);

  useEffect(() => {
    async function applyPartnerPromoCode() {
      if (partnerPromoCode && jobIdFromUrl) {
        const response = await PromocodeApi.retrievePromoData(partnerPromoCode);
        if (response && response.used_by && response.promocode_status !== "expired") {
          if (user && user.id) {
            const findUser = response.used_by.find(
              (item) => item.user_id === user.id
            );
            if (!findUser) {
              setShowPromoCodeInputField(true)
              await handlePromocode()
            }
          }else {
            setShowPromoCodeInputField(true)
            await handlePromocode()
          }
        }
      }
    }
    applyPartnerPromoCode();
  }, []);
  
  useEffect(() => {
    if (!discountModalShown) {
      if (planId) {
        setTimeout(() => {
          setShowtwentyPercentModal(true);
          setDiscountModalShown(true);
        }, showSubscriptionPlanModalTime);
      }
    }
  }, [showSubscriptionPlanModalTime]);

  useEffect(() => {
    if (process.env.REACT_APP_URL) {
      const appUrl = process.env?.REACT_APP_URL?.split("/")[2] || false;
      PushUserDataToGtm("b2b_purchase", user, appUrl);
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (user.roles[0] !== "owner") {
        window.location.href = "/";
      }
      if (jobIdFromUrl) {
        // console.log("My console from job summary", jobIdFromUrl);
        fetchJob(jobIdFromUrl);
        const res = await SoftwareApi.getSoftwareList();
        if (res) {
          // console.log("software api response from job summary component", res);
          setSoftwareList(res.data);
        }
      }
    })();
  }, [hiddenSoftwares]);

  useEffect(() => {
    if (job) {
      if (user) {
        if (job.status !== "Draft") window.location.href = "/";
      }
      if (user && user.email === "guest@geeker.co") {
        if (job?.guestJob) localStorage.removeItem(SECRET_KEY);
      }
    }
  }, [job]);

  // useEffect(() => {
    // console.log("My console for customersDefaultCC", customersDefaultCC);
  // }, [customersDefaultCC]);

  useEffect(() => {
    (async () => {
      liveUser = await isLiveUser(user);
      // console.log("Initial data in useEffect", { planId, user });
      //Checking if customer already have card added to account.
      if (user) {
        let customer_info =
          await CustomerApi.checkIfOrganisationHasSubscription({
            user: user,
            liveUser: liveUser,
          });
        if (!user.customer.askedForBusiness) {
          await CustomerApi.updateCustomer(user.customer.id, {
            askedForBusiness: false,
          });
        }
        // console.log("My console for customer_info", customer_info);
        if (customer_info.has_card_or_subscription) {
          setShowCCForm(false);
          let cardsInfo = await CustomerApi.getStripeCustomerCardsInfo({
            stripe_id: user.customer.stripe_id,
            liveUser: liveUser,
          });
          // console.log("My console for all user's card", cardsInfo.data);

          //Removing duplicate cards from array
          const filteredDuplicateCreditCards = cardsInfo.data.filter(
            (value, index, self) =>
              index ===
              self.findIndex((t) => t.fingerprint === value.fingerprint)
          );
          // console.log(
          //   "My console for filteredArr",
          //   filteredDuplicateCreditCards
          // );

          setCustomersAllCC(filteredDuplicateCreditCards);

          const defaultCard = cardsInfo.data.filter(
            (card) => card.default_card === "yes"
          );
          // console.log("My console for user's default card", defaultCard);

          setCustomersDefaultCC(defaultCard[0]);
          let selectedCard = { data: defaultCard };
          setSelectedCreditCard(selectedCard);
          setCustomersDefaultCCBrand(defaultCard[0].brand);
        }
        //Fetching promocode data
        let promolist = await PromoApi.retrieveCustomerPromoCodes({
          customer_id: user.customer.id,
          redeemed: true,
        });

        // console.log("promolist:::::", promolist);
      }
      if (jobIdFromUrl) {
        const jobRes = await JobApi.retrieveJob(jobIdFromUrl);
        setJobData(jobRes);
        setIsLoading(false);
      }

      //Getting subscriotion plan info.
      if (planId) {
        const totalCusotmer = await PublicApi.getTotalCustomerCount({
          "subscription.plan_id": planId,
        });
        // console.log(
        //   "totalCusotmer with current subscription plan ",
        //   totalCusotmer
        // );
        setTotalCustomerWithThisSubscrition(totalCusotmer.totalCount);
        let allPlans = await getAllPlans({
          liveUser: user.customer.customerType === "live",
        });
        const currentPlan = allPlans.data.filter((item) => item.id === planId);
        const keyFeatures = currentPlan[0].metadata.key_features
          .replace("[", "")
          .replace("]", "")
          .replaceAll(`"`, "")
          .split(",");
        setPlanInfo(keyFeatures);
        setPlanData(currentPlan[0]);
        setIsLoading(false);
        if (couponId !== "") {
          // console.log("Coupon code", couponId);
          const couponCodeInfo = await StripeApi.getCouponInfo({
            id: couponId,
            liveUser: user.customer.customerType === "live",
          });
          // console.log("couponCodeInfo", couponCodeInfo);
          const discountPercentage = couponCodeInfo.data.percent_off;
          // console.log("couponCodeInfo 2", { currentPlan, discountPercentage });
          if (discountPercentage === 100) {
            // console.log("100 % discount");
            setCouponDiscountedPrice(0);
          } else {
            const finalDiscountedPrice =
              (planData?.price?.unit_amount / 100) *
              ((100 - discountPercentage) / 100);
            // console.log("finalDiscountedPrice", finalDiscountedPrice);
            setCouponDiscountedPrice(finalDiscountedPrice);
          }
          const previouslyAppliedCoupons =
            await AppliedCoupons.getusedCouponsByCustomerId(user.customer.id);
          // console.log("previouslyAppliedCoupons", previouslyAppliedCoupons);
          for (let x in previouslyAppliedCoupons) {
            if (previouslyAppliedCoupons[x].coupon_id === couponId) {
              setCouponAlreadyUsed(true);
              return;
            }
          }
        }
      } else if (window.location.href.includes("registered")) {
        setIsLoading(false);
      }
      // console.log("My console for live user", liveUser);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (!user) {
        //Login temporarily as guest user to make backend requests
        const guestUserRes = await getGuestUser();
         console.log("console to check guest user");
        //Fetch job data as guest user.
        const fetchUserRes = await fetchJobAsGuest(
          jobIdFromUrl,
          guestUserRes.token.accessToken
        );
        // console.log("My console to fetch job as guest user", fetchUserRes);
        setJob(fetchUserRes);
        setCalculatedPrices(calculatePrice(fetchUserRes.software));
        setJobData(fetchUserRes);
        if (fetchUserRes?.guestJob) {
          // console.log("tetch token removed from component jobSummary index");
          localStorage.removeItem(SECRET_KEY);
        }
        // console.log("My con from job summary component if", showCCForm);
        setDisableBtn(false);
      } else {
        const jobRes = await JobApi.retrieveJob(jobIdFromUrl);
        setJobData(jobRes);
        setCalculatedPrices(calculatePrice(jobRes.software));
        console.log(" con from job summary component else");
        setDisableBtn(false);
      }
      const res = await SoftwareApi.getSoftwareList();
      if (res) {
        // console.log("software api response from job summary component", res);
        setSoftwareList(res.data);
      }
    })();
  }, [isJobSummaryUpdate, hiddenSoftwares]);
  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };
  const calculatePrice = (
    softwareData,
    hire_expert = false,
    forfreeMinutes = false
  ) => {
    let initPriceToShow = 0;
    let finalPriceToShow = 0;
    try {
      let price_per_six_min = softwareData.rate;
      let time1 =
        softwareData && String(softwareData.estimatedTime).indexOf("-") !== -1
          ? parseInt(String(softwareData.estimatedTime).split("-")[0])
          : 0;
      let time2 =
        softwareData && String(softwareData.estimatedTime).indexOf("-") !== -1
          ? parseInt(String(softwareData.estimatedTime).split("-")[1])
          : 0;
      let main_price = "";
      if (hire_expert) {
        main_price = softwareData.twoTierEstimatePrice;
      } else {
        main_price = softwareData.estimatedPrice;
      }
      // console.log("> main price >>>>>>>>> ", main_price);
      let price1 =
        softwareData && String(main_price).indexOf("-") !== -1
          ? parseInt(String(main_price).split("-")[0])
          : 0;
      let price2 =
        softwareData && String(main_price).indexOf("-") !== -1
          ? parseInt(String(main_price).split("-")[1])
          : 0;

      price1 = price1 ? price1 : price_per_six_min;
      price2 = price2 ? price2 : price_per_six_min;
      initPriceToShow = forfreeMinutes
        ? (Math.ceil(time1 / 6) - 1) * parseInt(price1)
        : Math.ceil(time1 / 6) * parseInt(price1);
      finalPriceToShow = forfreeMinutes
        ? (Math.ceil(time2 / 6) - 1) * parseInt(price2)
        : Math.ceil(time2 / 6) * parseInt(price2);

      initPriceToShow =
        initPriceToShow && initPriceToShow > 0 ? initPriceToShow.toFixed(0) : 0;
      finalPriceToShow =
        finalPriceToShow && finalPriceToShow > 0
          ? finalPriceToShow.toFixed(0)
          : 0;

      // console.log("initPriceToShow >>>>>>>>>> ", initPriceToShow);
    } catch (err) {
      console.error("issue in calculating price :::: ", err);
    }
    return {
      initPriceToShow: initPriceToShow,
      finalPriceToShow: finalPriceToShow,
    };
  };

  const handlePromocode = async () => {
    if (planId) {
      const couponInfo = await StripeApi.getCouponInfo({
        id: couponId,
        liveUser: user.customer.customerType === "live",
      });
      console.log("My console for couponInfo");
      if (couponInfo.success) {
        openNotificationWithIcon(
          "success",
          "Success",
          "Promocode applied successfully"
        );
      } else {
        openNotificationWithIcon(
          "error",
          "Invalid Promocode",
          couponInfo.errorMsg
        );
      }
    }

    if (jobIdFromUrl) {
      if (promoId) {
        console.log("My console for promocode");
        setIsPromocodeApplied({});
        const promocode = promoId.trim();
        // retrieving promocode data to db
        const response = await PromocodeApi.retrievePromoData(promocode);
        // console.log("response- when coupon code is applied", promocode);
        if (response) {
          if (response && response.message) {
            return openNotificationWithIcon("error", "Error", response.message);
          }
          if (
            response &&
            response.expiry_date &&
            new Date(response.expiry_date).setHours(0, 0, 0, 0) <
              new Date().setHours(0, 0, 0, 0)
          ) {
            return openNotificationWithIcon("error", "Error", MESSAGES[0]);
          }
          if (response && response.used_by) {
            if (user && user.id) {
              const findUser = response.used_by.find(
                (item) => item.user_id === user.id
              );
              // console.log("Check User Exists ?", findUser);
              if (findUser && !(JSON.stringify(findUser) === "{}")) {
                return openNotificationWithIcon("error", "Error", MESSAGES[1]);
              } else {
                setIsPromocodeApplied(response);
                setPromoApplied(true);
                const message =
                  response.discount_type === "fixed"
                    ? `Promocode of $${response.discount_value} is applied`
                    : `Promocode of ${response.discount_value}% is applied`;
                setPromoCodeValue(response.discount_type === "fixed" ? '$' + response.discount_value : response.discount_value + "%");
                return openNotificationWithIcon("success", "Success", message);
              }
            }
          }
        } else {
          return openNotificationWithIcon("error", "Error", MESSAGES[4]);
        }
      } else {
        return openNotificationWithIcon("error", "Error", MESSAGES[3]);
      }
    }
  };

  const changeDefaultCard = async (card_id) => {
    try {
      if (hasCardChanged) {
        let isUserLive = await isLiveUser(user);
        let updatingCardInfo = await CustomerApi.updateDefaultCard({
          liveUser: isUserLive,
          card_id: card_id,
          customer_id: user?.customer?.stripe_id,
        });
        // mixpanel code//
        mixpanel.identify(user?.email);
        mixpanel.track(
          "Customer - Customer has change the card while  buySubscriptionPlan."
        );
        // mixpanel code//
        // console.log("My console for cardsInfo 2", { card_id, isUserLive, stripe_id: user?.customer?.stripe_id });
        return true;
      }
      return false;
    } catch (error) {
      // Handle the error here
      console.error("An error occurred in changeDefaultCard() :", error);
      return false;
    }
  };

  const buySubscriptionPlan = async (
    cardsInfo,
    price_id,
    plan_id,
    plan_name,
    total_minutes,
    discount,
    subscriptionHistory = [],
    promoId = "",
    couponId = ""
  ) => {
    openNotificationWithIcon("info", "Info", "Buying subscription ....");
    // console.log("My console for cardsInfo ", cardsInfo)
    let cardObj;
    let card_id;
    // Look for default card if cardInfo contains more than one card
    if (cardsInfo.length > 1) {
      cardObj = cardsInfo.data.find((o) => o.default_card === "yes");
      card_id = cardObj?.id;
    } else {
      cardObj = cardsInfo.data[0];
      card_id = cardsInfo?.data[0]?.id;
    }
    if (hasCardChanged) {
      await changeDefaultCard(card_id);
    }
    let purchaseDate = moment().format("MM/DD/YYYY hh:mm a");
    let subscribeDataToSend = {
      customer_id: cardObj["customer"],
      price_id: price_id,
      product_id: plan_id,
      email: user.email,
      name: user.firstName + " " + user.lastName,
      liveUser: user.customer.customerType === "live",
      plan_purchased_date: moment(purchaseDate).format("MM-DD-YYYY"),
    };
    if (promoId !== "" || couponId !== "") {
      subscribeDataToSend.promoId = promoId;
      subscribeDataToSend.couponId = couponId;
    }
    // console.log("subscribeDataToSend>>>>>>>>>>", subscribeDataToSend)
    let sRes = await buySubscription(subscribeDataToSend);

    // console.log('sRes Data', sRes)
    if (sRes && sRes.success) {
      let cust_id = user.customer.id;
      let planDetails = {};
      total_minutes = parseInt(total_minutes);
      planDetails["plan_id"] = plan_id;
      planDetails["plan_name"] = plan_name;
      planDetails["plan_purchased_date"] = new Date();
      planDetails["total_minutes"] = total_minutes;
      planDetails["total_seconds"] = total_minutes * 60;
      planDetails["previous_seconds"] = 0;
      planDetails["subscription_plan_seconds"] = total_minutes * 60;
      planDetails["time_used"] = 0;
      planDetails["invoice_id"] = sRes.data.latest_invoice;
      planDetails["subscription_id"] = sRes.data.id;
      planDetails["discount"] = discount;
      planDetails["status"] = sRes.data.status;
      planDetails["paidPrice"] = sRes.paidPrice;
      planDetails["priceOff"] = sRes.priceOff;
      planDetails["cancellation_requested"] ='false';

      if (user?.customer?.subscription) {
        // console.log("My console to chk for subs 1", {
        //   sub: user?.customer?.subscription,
        // });
        if (user?.customer?.subscription?.time_from_previous_subscription) {
          // console.log(
          //   "My console to chk for subs time_from_previous_subscription",
          //   { sub: user?.customer?.subscription }
          // );
          planDetails["time_from_previous_subscription"] =
            user?.customer?.subscription?.time_from_previous_subscription +
            (user?.customer?.subscription?.total_seconds -
              user?.customer?.subscription?.time_used);
        } else {
          // console.log(
          //   "My console to chk for subs time_from_previous_subscription not exists",
          //   { sub: user?.customer?.subscription }
          // );
          planDetails["time_from_previous_subscription"] =
            user?.customer?.subscription?.total_seconds -
            user?.customer?.subscription?.time_used;
        }

        // console.log("My console to chk for subs 2", { sub: user?.customer?.subscription })
        if (user?.customer?.subscription?.time_from_previous_subscription) {
          // console.log("My console to chk for subs grand_total_seconds", {
          //   sub: user?.customer?.subscription,
          // });
          planDetails["grand_total_seconds"] =
            user?.customer?.subscription?.time_from_previous_subscription +
            (user?.customer?.subscription?.total_seconds -
              user?.customer?.subscription?.time_used) +
            total_minutes * 60;
        } else {
          // console.log("My console to chk for subs grand_total_seconds 2", {
          //   sub: user?.customer?.subscription,
          // });
          planDetails["grand_total_seconds"] =
            user?.customer?.subscription?.total_seconds -
            user?.customer?.subscription?.time_used +
            total_minutes * 60;
        }
      } else {
        planDetails["grand_total_seconds"] = total_minutes * 60;
      }

      if (subscriptionHistory && subscriptionHistory.length > 0) {
        await CustomerApi.updateCustomer(user.customer.id, {
          subscription: planDetails,
        });
      } else {
        await CustomerApi.updateCustomer(user.customer.id, {
          subscription: planDetails,
        });
      }
      openNotificationWithIcon("success", "Success", sRes.messageToDisplay);
      setbusinessPlanStepNumber(jobFlowStepsObj["GotOurGeeks"]);
    } else if (sRes && sRes.success == false) {
      if (sRes.errorMessage) {
        openNotificationWithIcon("error", "Error", sRes.errorMessage);
      } else {
        openNotificationWithIcon("error", "Error", sRes.messageToDisplay);
      }
      setShowCCForm(true);
      setDisableBtn(false);
    }
  };

  const handlePurchase = async () => {
    if (couponAlreadyUsed) {
      openNotificationWithIcon(
        "error",
        "Error",
        "You already used up this coupon"
      );
      return;
    }
    setShowSubscriptionPlanModalTime(150000);
    setDisableBtn(true);

    //if customer already have a card
    if (!showCCForm) {
       console.log("Customer have card");
      let subscriptionHistory = user.customer.subscription_history;
      buySubscriptionPlan(
        selectedCreditCard,
        planData.price.id,
        planData.id,
        planData.name,
        planData.metadata.total_minutes,
        planData.metadata.discount,
        subscriptionHistory,
        promoId,
        couponId
      );
    } else {
       console.log("No card");
      if (jobIdFromUrl && promoId && promoCodeApplied) {
        const requiredData = {
          promoCodeId: promoCodeApplied.id,
          promoCode: promoCodeApplied.promo_code,
          discountType: promoCodeApplied.discount_type,
          couponcodeDiscount: promoCodeApplied.discount_value,
        };
        // console.log(
        //   "Promocode Discount Details- Guest User Side",
        //   requiredData
        // );
        await window.sessionStorage.setItem(
          "promo-code-details",
          JSON.stringify(requiredData)
        );
      }
      const cardElement = elements.getElement(CardNumberElement);
      var dataToStripe = {};
      dataToStripe["metadata"] = formData;
      if (!stripe || !elements) {
        setDisableBtn(false);
        return;
      }
      stripe.createToken(cardElement, dataToStripe).then(async (payload) => {
        // console.log("payload>>>>>>>>>>>>>>>>>>>>", payload);
        if (payload["error"]) {
          setDisableBtn(false);
          openNotificationWithIcon(
            "error",
            "Error",
            payload["error"]["message"]
          );
          return;
        } else {
          if (formData.zip === "") {
            setDisableBtn(false);
            openNotificationWithIcon(
              "error",
              "Error",
              "Zip cannot be left empty"
            );
            return;
          } else {
            await UserApi.updateUser({ userId: user.id, zip: formData.zip });
          }
          // retrieve customer's strip id to Db
          let retrieve_cust = await CustomerApi.retrieveCustomer(
            user?.customer?.id
          );
          // console.log(
          //   "addCardForm handleSubmit retrieve_cust::",
          //   retrieve_cust
          // );
          if (
            !retrieve_cust.stripe_id ||
            retrieve_cust.stripe_id === "" ||
            retrieve_cust.stripe_id == null
          ) {
            checkCardAndAddCardToCustomer(
              cardElement,
              dataToStripe,
              payload,
              true,
              false
            );
          } else {
            checkCardAndAddCardToCustomer(
              cardElement,
              dataToStripe,
              payload,
              false,
              retrieve_cust.stripe_id
            );
          }
        }
      });
    }
  };

    // This function is used to refund the holded amount to the customer back after authorization
    const refundHoldedMoney = async (holdChargeResponse) => {
      // This will refund the hold money from customer account
      if (
        holdChargeResponse?.payment_id &&
        holdChargeResponse?.payment_status == "requires_capture"
      ) {
        const obj = {
          payment_hold_id: holdChargeResponse?.payment_id,
          isDeduct: false,
          jobId: "NA",
          stripe_id: holdChargeResponse?.stripe_id,
        };
        const cancelledResponse = await CustomerApi.deductOrRefundHoldMoney(obj);
        // mixpanel code//
        mixpanel.identify(user.email);
        mixpanel.track("Customer - Refund $40 from customer", cancelledResponse);
        // mixpanel code//
        return cancelledResponse;
      }
    };

  async function checkCardAndAddCardToCustomer(
    cardElement,
    data,
    payload,
    newCustomer,
    stripe_customer_id
  ) {
    let updatedCustomer;
    if (newCustomer) {
      // console.log("addCardForm handleSubmit createCustomerStripe ::");
      // creating customer's stripe id
      const result_customer = await CustomerApi.createCustomerStripe({
        email: user.email,
        liveUser: user.customer.customerType === "live",
      });

      var customer_id = result_customer.id;
      // updating customers strip id in DB
      updatedCustomer = await CustomerApi.updateCustomer(user.customer.id, {
        stripe_id: customer_id,
      });
      stripe_customer_id = customer_id;
      if (job && job.id) {
        // updating job.id
        JobApi.updateJob(job.id, { tech_search_start_at: new Date() });
      }
    }
    //adding card to customer's strip.id
    let result_card = await CustomerApi.addCardToCustomerStripe({
      liveUser: user?.customer?.customerType === "live",
      stripe_id: stripe_customer_id,
      token_id: payload.token.id,
      planId: planId ? planId : "NA",
      jobId: job && job.id ? job.id : "NA",
    });

    if (result_card["error"] != undefined) {
      setDisableBtn(false);
      openNotificationWithIcon(
        "error",
        "Error",
        result_card["error"]["message"]
      );
      // mixpanel code//
      mixpanel.identify(user.email);
      mixpanel.track("Customer - Card not added due to some error in card.");
      // mixpanel code//
    } else {
      // Making the new card as default card
      if (result_card["id"]) {
        await CustomerApi.updateDefaultCard({
          liveUser: user?.customer?.customerType === "live",
          card_id: result_card["id"],
          customer_id: stripe_customer_id,
        });
      }

      // openNotificationWithIcon("success", "Success", "Card details has been saved.")

      // mixpanel code//
      mixpanel.identify(user.email);
      mixpanel.track("Customer - Card details added.");
      // mixpanel code//
      if (jobIdFromUrl) {
        // Holding $100 Dollars here from customer which will be refunded or deducted depnding on customer action
        const custObj = {
          stripe_id: stripe_customer_id,
          liveUser: user?.customer?.customerType === "live",
          jobId: job?.id,
        };

        try {
          let response = await fetchChatOrCreate(job, user);
          // console.log("chatCreate response: ", response);
        } catch (e) {
          console.log("fetchChatOrCreate chat not created yet", e);
        }
        // console.log("custObj:::", custObj);
        const holdChargeResponse =
          await CustomerApi.holdChargeFromCustomer(custObj);
        // mixpanel code//
        mixpanel.identify(user.email);
        mixpanel.track(
          "Customer - Hold $40 from customer",
          holdChargeResponse
        );
        // mixpanel code//

        // This condition check if $40 hold is not successful then redirect to dashboard
        if (holdChargeResponse.status !== "Successful") {
          if (jobIdFromUrl) {
            await JobCycleApi.create(JobTags.HOLD_CHARGE_FAIL, jobIdFromUrl);
          }
          const card_id = holdChargeResponse?.response?.source;
          const stripe_id = holdChargeResponse?.response?.customer;
          console.log("checking we are getting details or not", {
            stripe_id,
            card_id,
          });
          if (card_id && stripe_id) {
            await removeCard(card_id, stripe_id);
          } else {
            const card_id = holdChargeResponse?.response?.source?.id;
            const stripe_id =
              holdChargeResponse?.response?.payment_intent?.customer;
            // console.log("checking we are getting details or not", {
            //   stripe_id,
            //   card_id,
            // });

            await removeCard(card_id, stripe_id);
          }
          openNotificationWithIcon(
            "error",
            "Error",
            holdChargeResponse.message
          );
          setDisableBtn(false);
          return;
        } else {
          if (jobIdFromUrl) {
            await JobCycleApi.create(JobTags.HOLD_CHARGE, jobIdFromUrl);
          }
          refetch("callPage5");
          let promoCodeDetails =
            await window.sessionStorage.getItem("promo-code-details");
          promoCodeDetails = JSON.parse(promoCodeDetails);
          // console.log("Checking Parsed Data is Available--", promoCodeDetails);
          await updatePromoCodeDetails(promoCodeDetails, jobIdFromUrl);
          openNotificationWithIcon(
            "success",
            "Success",
            "Card details has been saved."
          );
          if (localStorage.getItem("isScheduleJob")==="true") {
            localStorage.removeItem("isScheduleJob");
            // updating scheduled job into DB
            const updatedJob = await JobApi.updateJob(jobIdFromUrl, {
              status: JOB_STATUS.SCHEDULED,
              customer: user.customer.id,
            });
            // console.log("My console to see updatedJob", updatedJob);
            await emitSocketCreateFetchNotification(updatedJob);
            setTimeout(() => {
              window.location.href =
                "/dashboard?&scheduleJobId=" + jobIdFromUrl;
              setDisableBtn(false);
            }, 2000);
          } else {
            // console.log("Going to Page helpIsOnTheWay");
            await JobApi.updateJob(jobIdFromUrl, {
              status: "Pending",
              tech_search_start_at: new Date(),
              customer: user.customer.id,
            });

            window.location.href = `/customer/profile-setup?page=tech-search&jobId=${jobIdFromUrl}`;
            setDisableBtn(false);
          }
        }
        if (user && job && job.GA_conversion_event_called === undefined) {
          GAevent(
            "Conversion",
            isScheduleJob ? "scheduled_job" : "new_job",
            "Conversion",
            user.customer.id ? user.customer.id : user.customer
          );
          JobApi.updateJob(job.id, { GA_conversion_event_called: "yes" });
        }
      } else {
        if (planId) {
          setDisableBtn(true);

          const custObj = {
            stripe_id: updatedCustomer?.stripe_id
              ? updatedCustomer?.stripe_id
              : stripe_customer_id,
            liveUser: user?.customer?.customerType === "live",
            jobId: "NA",
          };
          const holdChargeResponse =
            await CustomerApi.holdChargeFromCustomer(custObj);

          if (holdChargeResponse.status !== "Successful") {
            if (jobIdFromUrl) {
              await JobCycleApi.create(JobTags.HOLD_CHARGE_FAIL, jobIdFromUrl);
            }
            const card_id = holdChargeResponse?.response?.source;
            const stripe_id = holdChargeResponse?.response?.customer;
            console.log("checking we are getting details or not", {
              stripe_id,
              card_id,
            });
            if (card_id && stripe_id) {
              await removeCard(card_id, stripe_id);
            } else {
              const card_id = holdChargeResponse?.response?.source?.id;
              const stripe_id =
                holdChargeResponse?.response?.payment_intent?.customer;
              // console.log("checking we are getting details or not", {
              //   stripe_id,
              //   card_id,
              // });

              await removeCard(card_id, stripe_id);
            }
            openNotificationWithIcon(
              "error",
              "Error",
              holdChargeResponse.message
            );
            setDisableBtn(false);
            return;
          } else {
              await refundHoldedMoney(holdChargeResponse);
            let cardsInfo;
            if (updatedCustomer || !newCustomer) {
              cardsInfo = await CustomerApi.getStripeCustomerCardsInfo({
                stripe_id: updatedCustomer?.stripe_id
                  ? updatedCustomer?.stripe_id
                  : stripe_customer_id,
                liveUser: user?.customer?.customerType === "live",
              });
            } else {
              cardsInfo = await CustomerApi.getStripeCustomerCardsInfo({
                stripe_id: user.customer.stripe_id,
                liveUser: user?.customer?.customerType === "live",
              });
            }
            console.log("Customer have card cardsInfo", cardsInfo);
            let subscriptionHistory = user.customer.subscription_history;
            buySubscriptionPlan(
              cardsInfo,
              planData.price.id,
              planData.id,
              planData.name,
              planData.metadata.total_minutes,
              planData.metadata.discount,
              subscriptionHistory,
              promoId,
              couponId
            );
          }
        } else {
          const custObj = {
            stripe_id: updatedCustomer.stripe_id,
            liveUser: user?.customer?.customerType === "live",
            jobId: "NA",
          };

          const holdChargeResponse =
            await CustomerApi.holdChargeFromCustomer(custObj);

          if (holdChargeResponse.status !== "Successful") {
            if (jobIdFromUrl) {
              await JobCycleApi.create(JobTags.HOLD_CHARGE_FAIL, jobIdFromUrl);
            }
            const card_id = holdChargeResponse?.response?.source;
            const stripe_id = holdChargeResponse?.response?.customer;
            // console.log("checking we are getting details or not", {
            //   stripe_id,
            //   card_id,
            // });
            if (card_id && stripe_id) {
              await removeCard(card_id, stripe_id);
            } else {
              const card_id = holdChargeResponse?.response?.source?.id;
              const stripe_id =
                holdChargeResponse?.response?.payment_intent?.customer;
              console.log("checking we are getting details or not", {
                stripe_id,
                card_id,
              });

              await removeCard(card_id, stripe_id);
            }
            openNotificationWithIcon(
              "error",
              "Error",
              holdChargeResponse.message
            );
            if(localStorage.getItem("partner") !== undefined && localStorage.getItem("partner") !== null){
              window.location.href = `/customer/profile-setup?page=select-software`;
            }
            window.location.href = "/";
          } else {
            await refundHoldedMoney(holdChargeResponse);
            if (chatMessage) {
              window.location.href = `/dashboard?message=${chatMessage}`;
            } else {
              window.location.href = `/`;
            }
          }
        }
      }
    }
  }
  // This function will remove the card if it fails to hold the amount
  const removeCard = async (card_id, stripe_id) => {
    const response = await CustomerApi.removeCard({
      liveUser: liveUser,
      card_id: card_id,
      customer_id: stripe_id,
    });
    return response;
  };
  // Update Details of User and JobID in Promocode Database
  const updatePromoCodeDetails = async (promoCodeDetails, jobId) => {
    try {
      console.log("Checking Parsed Data is Available--", promoCodeDetails);
      if (promoCodeDetails && promoCodeDetails.promoCodeId) {
        // const updateData = {
        //   user_id: user && user.id,
        //   job_id: jobId,
        //   used_date: new Date(),
        // };
        // console.log("updateData", updateData);
        // updating promocode details to db
        // const updateResponse = await PromocodeApi.updatePromoData(
        //   promoCodeDetails.promoCodeId,
        //   updateData
        // );
        // if (updateResponse) {
          const updateUser = {
            coupon_id: promoCodeDetails.promoCodeId,
            coupon_code: promoCodeDetails.promoCode,
            discount_type: promoCodeDetails.discountType,
            coupon_code_discount: promoCodeDetails.couponcodeDiscount,
          };
          await updateJob(jobId, updateUser);
          sessionStorage.removeItem("promo-code-details");
          return;
        // }
      } else {
        return;
      }
    } catch (error) {
      console.error(
        "updating job details with promocode if applied  : error ",
        error
      );
    }
  };

  /**
   * emit send-schedule-alerts socket and create / fetch notification customer notifications
   * @params : jobStats(Type:Object): Have job details
   * @returns : null
   * @author : Ridhima Dhir
   */
  const emitSocketCreateFetchNotification = async (jobStats) => {
    try {
      // console.log("send-schedule-alerts :::::::::::", jobStats);
      //Notification for customer
      const notificationData = {
        user: user.id,
        job: jobStats.id,
        read: false,
        actionable: false,
        title:
          "We are finding a technician for you. We will inform you when we find the technician",
        type: "Scheduled Job",
      };
      // console.log("notificationData ::::::::", notificationData);
      await createNotification(notificationData);
      console.log("fetchNotifications() :: called emitSocketCreateFetchNotification function in completeyourpurchase.ts");
      await fetchNotifications({ user: user.id });

      // console.log("My console to see now");

      // call send-schedule-alerts socket from backend.
      // It will find available techs and send alerts by sms/email/notification
      socket.emit("search-for-tech", {
        jobId: jobStats.id,
        customerTimezone: user.timezone,
        jobData: jobStats,
        primaryTime: jobStats.primarySchedule,
        phoneNumber: user.customer.phoneNumber,
        customerName: user.firstName,
        customerEmail: user.email,
        technicianId: technicianId ? technicianId : false,
      });
    } catch (err) {
      mixpanel.identify(user.email);
      mixpanel.track("There is catch error while create/fetch notification", {
        jobStats: jobStats,
        errMessage: err.message,
      });
      console.error(
        "There is catch error while create/fetch notification  :::: " +
          err.message
      );
    }
  };
  const handleChange = () => {
    setShowSubscriptionPlanModal(true);
    setShowSubscriptionPlanModalTime(150000);
  };

  const handleJobEdit = () => {
    setShowEditJobModal(true);
    setIsJobSummaryUpdate(false);
  };

  const handleRadioButtonChange = (e) => {
    // console.log("My console for handleRadioButtonChange", e.target.value);
    let selectedCard = { data: [e.target.value] };
    setSelectedCreditCard(selectedCard);
    setCustomersDefaultCC(e.target.value);
    setHasCardChanged(true);
  };

  const handleCreditCardChange = () => {
    setChangeCreditCard(true);
  };

  const handlePromoCodeAndCouponCodeChange = (e) => {
    if (jobIdFromUrl) {
      setPromoId(e.target.value);
    }
    if (planId) {
      setCouponId(e.target.value);
    }
  };

  if (isLoading) return <Loader height="500px" />;

  const handleCompletionCheck = (event, key) => {
    if (event.complete) {
        setCardDetailsFilled((prevData) => ({ ...prevData, [key] : true }));
    }
  };

  const fillZipCodeDetails = (e)=>{
    setFormData({ zip: e.target.value })
    setCardDetailsFilled((prevData) => ({ ...prevData, zip : true }));
  }

  function formatNumberWithCommas(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <div className={jobIdFromUrl ? "custom-container min-height-inherit p-0" : ""} style={jobIdFromUrl ? {} : { paddingLeft: "30px",paddingRight:"30px" }}>
      <Modal
        visible={isModalOpen}
        closable={false}
        centered
        maskStyle={{ backgroundColor: "#DCE6EDCF" }}
        onOk={false}
        footer={null}
      >
        <div className="card-info-main-div">
          <Row>
            <span className="card-info-content">
              Your credit card will be "pre-authorizated" an estimated amount
              which is about the average of your job. This pre-authorization
              confirms that the funds are available on your credit card and puts
              a "hold" on those funds. You are NOT charged this amount, final
              cost is based on call time and may be more or less.
            </span>
          </Row>
          <Row className="d-flex justify-content-end mt-2">
            <BasicButton
              id="pop-up-modal"
              btnTitle={"OK"}
              height={"40px"}
              width={"55px"}
              background={"#01D4D5"}
              color={"white"}
              faFontSize={"18px"}
              onClick={() => setIsModalOpen(false)}
            />
          </Row>
        </div>
      </Modal>
      <Header/>
      <Row className="min-height-inherit d-flex justify-content-center align-items-center parent-row">
        <Col
          md={9}
          xs={12}
          className="d-flex flex-column min-height-inherit max-width-768-mb-20px"
        >
          {planId && <ProgressBar currentStep={3} />}
          <div className="d-flex flex-column justify-content-center align-items-center min-height-inherit">
            <div className="mb-1 text-center">
              {planId ? (
                <HeadingText
                  firstBlackText={"Complete "}
                  secondGreenText={" your purchase"}
                />
              ) : (
                <HeadingText
                  firstBlackText={"Please add your "}
                  secondGreenText={" card"}
                />
              )}
            </div>
            <div className="mb-50">
              {/* <SubHeadingText
                text={
                  "*This is to verify your card. You won’t be charged until you get help! "
                }
              /> */}
            </div>
            {planId && (
              <div className="selected-plan-summary mb-20" style={{ 
                width: "100%",
                maxWidth: "500px",
               }}>
                <Row>
                  <Col
                    md={6}
                    sm={12}
                    xs={12}
                    className={`selected-plan-summary-col  ${
                      disableBtn ? "my-pe-none" : ""
                    }`}
                  >
                    <div className="mb-2">
                      <div>
                        <span className="small-team-text">
                          {planData?.name}
                        </span>
                      </div>
                      {/* <div className="mb-25">
                        <span className="purchase-number">
                          {totalCustomerWithThisSubscrition > 30
                            ? totalCustomerWithThisSubscrition
                            : "30"}{" "}
                          Purchased this plan!
                        </span>
                        <span className="clap-emoji">👏🏻</span>
                      </div> */}
                      {!queryParams.get("couponCode") && (
                        <div className="mb-0.5">
                          {/* {planData?.metadata?.reg_price &&
                            <>
                              <span className="actual-price">
                                {`$${formatNumberWithCommas(planData?.metadata?.reg_price)}/mo`}
                              </span>
                              &nbsp;&nbsp;
                            </>
                          } */}
                          <span className="discounted-price">
                            {/* ${planData?.price?.unit_amount / 100}/mo */}
                            {`$${formatNumberWithCommas(planData?.price?.unit_amount / 100)}/mo`}
                          </span>
                        </div>
                      )}
                      {queryParams.get("couponCode") && (
                        <div className="mb-0.5">
                          {couponDiscountedPrice &&
                            <>
                              <span className="actual-price">
                                $${planData?.price?.unit_amount / 100}/mo
                              </span>
                              &nbsp;&nbsp;
                              <span className="discounted-price">
                                $${couponDiscountedPrice}/mo
                              </span>
                            </>
                          }
                          {!couponDiscountedPrice &&
                            <span className="">
                              $${planData?.price?.unit_amount / 100}/mo
                            </span>
                          }
                        </div>
                      )}
                      {/* <span className="change-text" onClick={handleChange}>
                                        Edit
                                    </span> */}
                    </div>
                  </Col>
                  <Col>
                    <div>
                      {planInfo &&
                        planInfo.map((ele) => {
                          return (
                            <div className="d-flex align-items-center">
                              <CheckInCircle
                                bgColor={"turcose"}
                                style={{ height: "13px", width: "13px" }}
                                checkStyle={{ color: "black" }}
                              />
                              &nbsp;&nbsp;
                              <span className="text-with-check">{ele}</span>
                            </div>
                          );
                        })}
                    </div>
                  </Col>
                </Row>
              </div>
            )}
            {jobIdFromUrl && (
              <div className="selected-plan-summary mb-20 max-w-500px w-100p mb-2">
                <Row>
                  <Col className="d-flex align-items-center justify-content-between p-2">
                    <SignUpHeading
                      heading={"Job Summary"}
                      fontSize={"20px"}
                      color={"#01D4D5"}
                      boldText={true}
                    />
                    <div
                      className="edit-icon-div"
                      title="Update job summary"
                      onClick={handleJobEdit}
                    >
                      <FontAwesomeIcon
                        className="editJobSummary"
                        icon={faPencilAlt}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col
                    md={6}
                    sm={12}
                    xs={12}
                    className={`selected-plan-summary-col  ${
                      disableBtn ? "my-pe-none" : ""
                    }`}
                  >
                    <div className="">
                      <div className="mb-8">
                        <span className="jobSummaryLabel">Software:</span>
                      </div>
                      <div>
                        <span className="jobSummaryInfo">
                          {jobData?.software?.name}
                        </span>
                      </div>
                      <div className="mb-8">
                        <span className="jobSummaryLabel">Area:</span>
                      </div>
                      <div>
                        <span className="jobSummaryInfo">
                          {jobData?.subOption}
                        </span>
                      </div>
                      <div className="mb-8">
                        <span className="jobSummaryLabel">Details:</span>
                      </div>
                      <div>
                        <span className="jobSummaryInfo">
                          {jobData?.issueDescription}
                        </span>
                      </div>
                    </div>
                  </Col>
                  <Col
                    md={6}
                    sm={12}
                    xs={12}
                    className="selected-plan-summary-col pr-0-imp"
                  >
                    <div className="">
                      <div className="mb-8">
                        <span className="jobSummaryLabel">
                          Estimated Wait Time:
                        </span>
                      </div>
                      <div>
                        <span className="jobSummaryInfo">{`${jobData?.software?.estimatedWait} min`}</span>
                      </div>
                      <div className="mb-8">
                        <span className="jobSummaryLabel">
                          Most jobs like yours take between:
                        </span>
                      </div>
                      <div>
                        <span className="jobSummaryInfo">{`${jobData?.software?.estimatedTime} min`}</span>
                      </div>
                      <div className="mb-8">
                        <span className="jobSummaryLabel">
                          Most jobs like yours cost between:
                        </span>
                      </div>
                      <div>
                        {user.roles[0] === "owner" ? (
                          <>
                            {user && !isFirsJob && (
                              <div>
                                <span className="job-summary-value">
                                  {`$${jobData?.software?.estimatedPrice
                                    .split("-")[0]
                                    .trim()} - `}
                                  {`$${jobData?.software?.estimatedPrice
                                    .split("-")[1]
                                    .trim()}`}
                                </span>
                                <span
                                  className="estimate-info-round-div ml-1 position-relative"
                                  onMouseOver={handleMouseEnter}
                                  onMouseOut={handleMouseLeave}
                                  role="button"
                                  onTouchStart={handleMouseEnter}
                                  onFocus={() => setIsHovering(true)}
                                  onBlur={() => setIsHovering(false)}
                                >
                                  <FontAwesomeIcon
                                    icon={faInfo}
                                    className="icon-of-i"
                                  />
                                  {isHovering ? (
                                    <div className="estimatePriceInfo-div">
                                      <div className="phoneNumberInfo-triangle"></div>
                                      <div className="estimatePriceInfo-inner-div ">
                                        <h6>
                                          Actual cost may be more or less.
                                        </h6>
                                        <p className="estimate-price">
                                          This estimate is to give you an idea
                                          of cost based on what other customers
                                          paid for jobs in your category. Your
                                          actual job cost is charged per minute
                                          while you're in the call on a
                                          "pay-as-you-go" basis depending on
                                          your category pay rate. (Software =
                                          $1.99/minute, IT = $2.49/minute).
                                        </p>
                                      </div>
                                    </div>
                                  ) : null}
                                </span>
                              </div>
                            )}
                            {/* {(!user || isFirsJob) &&
                                                <span className="strike-through">
                                                    {`$${jobData?.software?.estimatedPrice.split("-")[0].trim()} - `}
                                                    {`$${jobData?.software?.estimatedPrice.split("-")[1].trim()}`}
                                                </span>
                                            }{" "} */}
                            {(!user || isFirsJob) && (
                              <div>
                                <span className="job-summary-value">
                                  {`$${jobData?.software?.estimatedPrice
                                    .split("-")[0]
                                    .trim()} - `}
                                  {`$${jobData?.software?.estimatedPrice
                                    .split("-")[1]
                                    .trim()}`}
                                </span>
                                <span
                                  className="estimate-info-round-div ml-1 position-relative"
                                  onMouseOver={handleMouseEnter}
                                  onMouseOut={handleMouseLeave}
                                  role="button"
                                  onTouchStart={handleMouseEnter}
                                  onFocus={() => setIsHovering(true)}
                                  onBlur={() => setIsHovering(false)}
                                >
                                  <FontAwesomeIcon
                                    icon={faInfo}
                                    className="icon-of-i"
                                  />
                                  {isHovering ? (
                                    <div className="estimatePriceInfo-div">
                                      <div className="phoneNumberInfo-triangle"></div>
                                      <div className="estimatePriceInfo-inner-div ">
                                        <h6>
                                          Actual cost may be more or less.
                                        </h6>
                                        <p className="estimate-price">
                                          This estimate is to give you an idea
                                          of cost based on what other customers
                                          paid for jobs in your category. Your
                                          actual job cost is charged per minute
                                          while you're in the call on a
                                          "pay-as-you-go" basis depending on
                                          your category pay rate. (Software =
                                          $1.99/minute, IT = $2.49/minute).
                                        </p>
                                      </div>
                                    </div>
                                  ) : null}
                                </span>
                              </div>
                            )}
                          </>
                        ) : (
                          "NA"
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            )}

            {!showCCForm && customersDefaultCC && !changeCreditCard && (
              <div className="max-w-500px w-100p mb-2">
                <SubHeadingText text={"Payment Method"} />
                <div className="business-plan-cc-div pt-pb-20-imp">
                  <div className="business-plan-cc-custom-container">
                    <Row>
                      <Col>
                        <div className="d-flex justify-content-between">
                          <div className="d-flex align-items-center">
                            <CardLogo
                              cardType={customersDefaultCCBrand}
                              imgClass={"card-logo mr-3"}
                            />
                            <span className="CC-last-four-digits">
                              ********{customersDefaultCC.last4}
                            </span>
                          </div>
                          <span
                            className="CC-change-text"
                            role="button"
                            onClick={handleCreditCardChange}
                          >
                            Change
                          </span>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            )}

            {changeCreditCard && customersAllCC && (
              <div className="max-w-500px w-100p mb-2">
                <SubHeadingText text={"Saved Credit Cards"} />

                <Radio.Group
                  name={"test"}
                  className="mb-35 ml-5 w-100p CC-radio-btns"
                  value={customersDefaultCC}
                >
                  {customersAllCC.map((singleCard, index) => {
                    return (
                      <div className="business-plan-cc-div pt-pb-20-imp mb-3">
                        <div className="business-plan-cc-custom-container">
                          <Radio
                            value={singleCard}
                            onChange={handleRadioButtonChange}
                            className="d-flex min-width-100p"
                          >
                            <Row>
                              <Col>
                                <div className="d-flex align-items-center">
                                  <CardLogo
                                    cardType={singleCard.brand}
                                    imgClass={"card-logo mr-3 ml-1"}
                                  />
                                  <span className="CC-last-four-digits">
                                    ********{singleCard.last4}
                                  </span>
                                </div>
                              </Col>
                            </Row>
                          </Radio>
                        </div>
                      </div>
                    );
                  })}
                </Radio.Group>
              </div>
            )}

            <div
              className="mb-2 max-w-500px w-100p d-flex m-top-10"
              style={{ flexFlow: "column" }}
            >
              <div className="mt-2 d-flex justify-content-between flex-wrap">
                {/*
                  <span className="business-plan-contract-text" style={{marginBottom:"10px"}}>Special Offer Activated: First job free (Your CC will be verified for the future, you will NOT be charged for this job)</span>
                */}
              
                <span className="business-plan-contract-text" style={{fontWeight:'100'}} >
                  *Billing doesn't start until after you connect and speak with a live technician <br />
                  &nbsp;You only pay per minute for time used.&nbsp;
                  <QuestionMarkOutlinedIcon
                    className="question_mark"
                    onClick={() => setIsModalOpen(true)}
                  />
                </span>
                
              </div>
            </div>

            {showCCForm && (
              <div className="max-w-500px w-100p mb-2">
                <SubHeadingText text={"Credit Card Details"} />
                <div className="business-plan-cc-custom-container">
                  <Row className="justify-content-evenly max-width-414px-gap-10">
                    <Col md={8}>
                        <Row className="business-plan-cc-div justify-content-between">
                          <Col
                            md={6}
                            sm={12}
                            className="d-flex justify-content-center align-items-center max-width-768-mb-20px"
                          >
                            <div>
                              <FontAwesomeIcon
                                icon={faCreditCard}
                                className="business-plan-cc-icon"
                              />
                            </div>
                            <CardNumberElement
                              className=""
                              options={{ placeholder: "Card Number" }}
                              onChange={(event) =>
                                handleCompletionCheck(event, "cardNumber")
                              }
                            />
                          </Col>
                          <Col
                            md={6}
                            sm={12}
                            className="d-flex justify-content-center max-width-280-flex-wrap max-width-280-justify-content-start align-items-center"
                          >
                            <CardExpiryElement
                              className="max-width-280-mb-10"
                              options={{ placeholder: "MM / YY" }}
                              disabled={true}
                              onChange={(event) =>
                                handleCompletionCheck(event, "expiryDate")
                              }
                            />
                            <CardCvcElement
                              className="max-width-280-mb-5"
                              options={{ placeholder: "CVC" }}
                              onChange={(event) =>
                                handleCompletionCheck(event, "cvc")
                              }
                            />
                          </Col>
                        </Row>
                    </Col>
                    <Col md={3}>
                        <Row className="business-plan-cc-div">
                          <Col
                            // md={12}
                            // sm={12}
                            className="d-flex justify-content-center max-width-280-flex-wrap max-width-280-justify-content-start align-items-center"
                          >
                            <input
                              id="zip-input"
                              type="text"
                              placeholder="Zip"
                              onChange={(e) => fillZipCodeDetails(e)}
                              className="business-plan-CC-zip"
                            />
                          </Col>
                        </Row>
                    </Col>
                  </Row>
                </div>
              </div>
            )}

            <SatisfactionGuaranteeBox>
              <GuaranteeTitle>100% Satisfaction Guaranteed!</GuaranteeTitle>
              <GuaranteeText>
                Our commitment: If you're not 100% satisfied, we'll make it right or your money back—guaranteed.
              </GuaranteeText>
            </SatisfactionGuaranteeBox>

            {queryParams.get("couponCode") ? (
              <div className="max-w-500px w-100p mt-20 mb-20">
                <span className="business-plan-promo-code">
                  {jobIdFromUrl ? "Promo Code" : "Coupon Code"}
                </span>
                &nbsp;
                <span className="business-plan-click-here">
                  {queryParams.get("couponCode")}
                </span>
                &nbsp;
                <span className="business-plan-promo-code">Applied</span>&nbsp;
              </div>
            ) : (
              !showPromoCodeInputField &&
              (planId || jobIdFromUrl) && (
                <div className="max-w-500px w-100p mt-20 mb-20">
                  <span className="business-plan-promo-code">
                    {jobIdFromUrl ? "Promo Code" : "Coupon Code"}?
                  </span>
                  &nbsp;
                  <span className="business-plan-click-here">
                    <a
                      onClick={() => {
                        setShowPromoCodeInputField(true);
                      }}
                    >
                      Click here
                    </a>
                  </span>
                </div>
              )
            )}
            {showPromoCodeInputField && !promoApplied && (
              <>
                <div
                  className={`max-w-500px w-100p business-plan-promocode-div mb-20 mt-10 ${
                    disableBtn ? "my-pe-none" : ""
                  }`}
                >
                  <input
                    type="text"
                    value={jobIdFromUrl ? promoId : couponId}
                    className="business-plan-promocode-input"
                    placeholder={jobIdFromUrl ? "promo code" : "coupon code"}
                    onChange={(e) => handlePromoCodeAndCouponCodeChange(e)}
                  />
                  <span
                    className="business-plan-apply"
                    onClick={handlePromocode}
                  >
                    Apply
                  </span>
                </div>
                {jobIdFromUrl && (
                  <span className="promo-code-text mr-5">
                    <ErrorIcon className="promo-code-text-icon" />
                    Promocode will work only if payment is deducted from your
                    card
                  </span>
                )}
              </>
            )}
            {showPromoCodeInputField && promoApplied && (
              <div className="promo_wrapper">
                <FontAwesomeIcon icon={faCheckCircle} />
                <span className="promo_Code-Apply">
                  {promoId} Applied!
                </span>
                <span className="promo_Code-Apply">
                  - {promoCodeValue}
                </span>
                <span
                  onClick={() => {
                    setPromoId("");
                    setPromoApplied(false);
                  }}
                  style={{ cursor: "pointer" , color: "#8d0000" , marginLeft: "10px" , backgroundColor: "#e7eef3" , padding: "5px 15px" , borderRadius: "5px" , fontSize: "15px" }}
                >
                  <FontAwesomeIcon icon={faTimes} />
                  {" "}
                  Remove
                </span>
              </div>
            )}
            {((cardDetailsFilled.cardNumber &&
              cardDetailsFilled.expiryDate &&
              cardDetailsFilled.cvc &&
              cardDetailsFilled.zip) ||
              !showCCForm) && (
              <div
                className="max-w-500px w-100p d-flex m-top-15"
                style={{ flexFlow: "column", alignItems: "center" }}
              >
                {/*<BasicButton style={{ backgroundColor: 'transparent', backgroundImage: 'url("/Button.png")', backgroundPosition: '0 -5px', backgroundSize: '245px' }} background="" moreClasses={'mr-10 text-dark'} onClick={() => { setShowOverlayLoading(true) }} btnTitle={""} height={"68px"} width={"246px"} color={"#fff"} />*/}
                <BasicButton
                  id="add-card-btn"
                  btnTitle={planId ? SUB_BTN_TITLE : "Next"}
                  height={"55px"}
                  width={"270px"}
                  background={"#01D4D5"}
                  color={"white"}
                  style={{ fontSize: "19px", fontWeight: "800" }}
                  btnIcon={planId ? "arrow" : ""}
                  faFontSize={"18px"}
                  arrowDirection={"right"}
                  onClick={handlePurchase}
                  disable={disableBtn}
                  showSpinner={disableBtn}
                />
              </div>
            )}
            <div className="max-w-500px w-100p">
              <img className="w-100p" src={stripeSecureLogo} />
            </div>
          </div>
        </Col>
        <Col md={3} xs={12} className="sign-in-side-column">
          <AvgInfo />
          <Testimony testimonyBy={"stacy"} />
        </Col>
      </Row>
      {jobIdFromUrl && (
        <EditJobModal
          softwareList={softwareList}
          jobData={jobData}
          showEditJobModal={showEditJobModal}
          setShowEditJobModal={setShowEditJobModal}
          user={user}
          setIsJobSummaryUpdate={setIsJobSummaryUpdate}
        />
      )}
    </div>
  );
};

const SatisfactionGuaranteeBox = styled.div`
  border: 1.5px dashed #00D4D5;
  border-radius: 8px;
  padding: 10px;
  margin-top: 20px;
  margin-bottom: 10px;
  text-align: left;
  background-color: #F0FEFF;
  transition: background-color 0.3s ease;
  max-width: 500px;

  &:hover {
    background-color: #E0F7FA;
  }
`;

const GuaranteeTitle = styled.h4`
  color: #00D4D5;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const GuaranteeText = styled.p`
  color: #333;
  font-size: 12px;
  margin: 0;
  word-wrap: break-word;
`;

export default CompleteYourPurchase;
