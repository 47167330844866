import React, { useState } from "react";
import { Col, Button } from "react-bootstrap";
import { Modal } from "antd";
import mixpanel from "mixpanel-browser";
import { useUser } from "../../context/useContext";
import { openNotificationWithIcon } from "../../utils";
import { klaviyoTrack } from "../../api/typeService.api";
import * as JobApi from "../../api/job.api";
import * as JobCycleApi from "../../api/jobCycle.api";
import { checkPendingStatus } from "../../utils";
import { JOB_STATUS, JobTags } from "../../constants/index";
import { useJob } from "../../context/jobContext";
import { isMobile, isTablet } from "react-device-detect";
import { useShabbat } from 'context/shabbatContext';

const CustomerTopBar = ({ setcurrentStep, setActiveMenu }) => {
  const { user } = useUser();
  const [customerConfirm, setCustomerConfirm] = useState(false);
  const [scheduleMsg, setScheduleMsg] = useState(false);
  const [lastPendingSoftware, setLastPendingSoftware] = useState("");
  const { updateJob } = useJob();
  const { isShabbat } = useShabbat();
  const [isDisabled, setIsDisabled] = useState(isShabbat);
  const [latestPendingJobToUpdate, setLatestPendingJobToUpdate] = useState();
  const [pendingJobModal, setPendingJobModal] = useState(false);
  const [inProgressMessage, setInProgressMessage] = useState("");
  const message = scheduleMsg ? (
    <span className="div-font" style={{ fontSize: 20, paddingTop: "40px" }}>
      One of your previous job of{" "}
      <b style={{ fontWeight: "bold" }}>{lastPendingSoftware}</b> is already
      scheduled with a technician. Are you sure you want to create a new job
      post?if yes, then your previous job will be{" "}
      <b style={{ fontWeight: "bold" }}>Cancelled</b>
    </span>
  ) : (
    <span className="div-font" style={{ fontSize: 20, paddingTop: "40px" }}>
      We are still looking for a technician for your existing job of{" "}
      <b style={{ fontWeight: "bold" }}>{lastPendingSoftware}</b>. Are you sure
      you want to create a new job post? if yes, then your previous job will be{" "}
      <b style={{ fontWeight: "bold" }}>Cancelled</b>
    </span>
  );

  const InprogressJobMsg = pendingJobModal ? (
    <span className="div-font" style={{ fontSize: 20, paddingTop: "40px" }}>
      {inProgressMessage}
    </span>
  ) : (
    <span></span>
  );

  const klaviyoTrackFunction = async () => {
    if (user?.customer?.customerType === "live") {
      let partnerName = '';
      let partnerStatus = false;
      if (user && user?.partner) {
        partnerName = user?.partner;
        partnerStatus = true;
      }
      // console.log("klaviyoTrackFunction ::::");
      const klaviyoData = {
        email: user?.email,
        event: "Job Post Button Click",
        properties: {
          $first_name: user?.firstName,
          $last_name: user?.lastName,
          $partnerName: partnerName,
          $partnerStatus: partnerStatus,
        },
      };
      await klaviyoTrack(klaviyoData);
    }

    if (window.localStorage.getItem("extraMin")) {
      window.localStorage.removeItem("extraMin");
    }
    if (window.localStorage.getItem("secs")) {
      window.localStorage.removeItem("secs");
    }
  };

  const push_to_profile_setup = async (e) => {
    console.log(">>>>>>>>>>>>push_to_profile_setup ::::::: TopBar");
    try {
      if (user) {
        // mixpanel code//
        mixpanel.identify(user?.email);
        mixpanel.track("Customer - Post a job");
        mixpanel.people.set({
          $first_name: user?.firstName,
          $last_name: user?.lastName,
        });
        // mixpanel code//
      }

      if (user) {
        // console.log("inside the latestJob:::: 1");
        if (
          latestPendingJobToUpdate &&
          latestPendingJobToUpdate.total_pending_jobs > 0
        ) {
          // console.log("inside the latestJob::: 3:", latestPendingJobToUpdate);
          const lastPendingJob = latestPendingJobToUpdate.last_pending_job;
          if (
            lastPendingJob &&
            (lastPendingJob.status === JOB_STATUS.PENDING ||
              lastPendingJob.status === JOB_STATUS.WAITING ||
              lastPendingJob.status === JOB_STATUS.SCHEDULED)
          ) {
            // console.log("inside the latestJob updateJob job :::::");
            await JobCycleApi.create(JobTags.CANCEL_JOB, lastPendingJob.id);
            const updatedJob = await updateJob(lastPendingJob.id, {
              status: "Declined",
            });
            // console.log(
            //   "inside the latestJob updateJob job ::::: 2",
            //   updatedJob
            // );
            await klaviyoTrackFunction();
            if (isMobile || isTablet) {
              window.location.href = "/post-job";
            } else {
              window.location.href =
                "/customer/profile-setup?page=select-software";
            }
          } else {
            await klaviyoTrackFunction();
            if (isMobile || isTablet) {
              window.location.href = "/post-job";
            } else {
              window.location.href =
                "/customer/profile-setup?page=select-software";
            }
          }
        } else {
          await klaviyoTrackFunction();
          if (isMobile || isTablet) {
            window.location.href = "/post-job";
          } else {
            window.location.href =
              "/customer/profile-setup?page=select-software";
          }
        }
      } else {
        openNotificationWithIcon(
          "error",
          "Error",
          "Something went wrong. Please logout and login again."
        );
      }
    } catch (e) {
      console.log("Err in catch block in push_to_profile_setup()", e);
    }
  };

  /**
   * Function will check if there are any pending jobs of the customer else it will call push_to_profile_setup function to find technician.
   * @author : Nafees
   */
  const checkPendingJobs = async () => {
    push_to_profile_setup();
    // try {
    //   if (user && user?.customer) {
    //     const latestpendingJobs = await JobApi.latestpendingJobs({
    //       customer: user.customer.id,
    //     });
    //     if (latestpendingJobs) {
    //       console.log("latest pending pob ::", latestpendingJobs);
    //       setLatestPendingJobToUpdate(latestpendingJobs);
    //       let pendingJobs = await checkPendingStatus(user);
    //       console.log("pendingJobs.lastPendingJob.length",pendingJobs.total_pending_jobs)
    //       if (pendingJobs.schedule_accepted) {
    //         setScheduleMsg(true);
    //       }
    //       function checkJobLimit(){
    //         if(pendingJobs.total_pending_jobs<6){

    //           push_to_profile_setup();
    //        }
    //        else{
    //          setCustomerConfirm(true)
    //        }
    //       }
    //       if (pendingJobs.success) {
    //         console.log("checkingqq",pendingJobs.schedule_accepted)
    //         setLastPendingSoftware(pendingJobs.name);

    //         if (
    //           pendingJobs.status === "Inprogress" ||
    //           pendingJobs.status === "Accepted" 
    //         ) {
    //           setPendingJobModal(true);
    //           setInProgressMessage(
    //             <span
    //               className="div-font"
    //               style={{ fontSize: 20, paddingTop: "40px" }}
    //             >
    //               One of your job of{" "}
    //               <b style={{ fontWeight: "bold" }}>{pendingJobs.name}</b> is
    //               already{" "}
    //               <b style={{ fontWeight: "bold" }}>{pendingJobs.status}</b>{" "}
    //               with a technician. Please complete that job first then post a
    //               new job.
    //             </span>
    //           );
    //         } else {
    //           checkJobLimit()
    //         }
    //       } else {
    //         checkJobLimit()
    //       }
    //     }
    //   }
    // } catch (e) {
    //   console.log("Error in checkPendingJobs ", e);
    // }
  };

  const closePendingModal = () => {
    setCustomerConfirm(false);
    setPendingJobModal(false);
  };
  return (
    <>
      <Col className="text-left pt-4 pr-0">
        <Button
          id="dash-get-help-now"
          onClick={checkPendingJobs}
          disabled={isDisabled || isShabbat}
          className={
            (isDisabled || isShabbat ? "disabled-btn" : "") + "btn app-btn app-btn-large"
          }
          style={{ backgroundColor: "#01D4D5", border: "#01D4D5" }}
        >
          <span />
          Get Help Now
        </Button>
      </Col>
      <Modal
        className="get-help-now-modal"
        closable={true}
        onCancel={closePendingModal}
        visible={customerConfirm}
        maskStyle={{ backgroundColor: "#DCE6EDCF" }}
        maskClosable={true}
        width={800}
        footer={[
          <div className="modal-flex-get-help-now">
            <Button
              className="btn app-btn app-btn-light-blue modal-footer-btn"
              onClick={() => {
                setCustomerConfirm(false);
              }}
              key="no"
            >
              <span></span>Back To Dashbord
            </Button>

            <Button
              id="confirm-create-new"
              className="btn app-btn job-accept-btn modal-footer-btn"
              onClick={push_to_profile_setup}
              key="yes"
            >
              <span></span>Create New
            </Button>
          </div>,
        ]}
      >
        <div className="">
          <span
            className="div-font"
            style={{ fontSize: 20, paddingTop: "40px" }}
          >
            {message}
          </span>
        </div>
      </Modal>
      <Modal
        className="get-help-now-modal"
        closable={true}
        onCancel={closePendingModal}
        visible={pendingJobModal}
        maskStyle={{ backgroundColor: "#DCE6EDCF" }}
        maskClosable={true}
        width={800}
        footer={[
          <div className="modal-flex-get-help-now">
            <Button
              id="confirm-create-new"
              className="btn app-btn job-accept-btn modal-footer-btn"
              onClick={closePendingModal}
              key="yes"
            >
              <span></span>Close
            </Button>
          </div>,
        ]}
      >
        <div className="">
          <span
            className="div-font"
            style={{ fontSize: 20, paddingTop: "40px" }}
          >
            {InprogressJobMsg}
          </span>
        </div>
      </Modal>
    </>
  );
};
export default CustomerTopBar;
