import { GTMProvider } from "@elgorditosalsero/react-gtm-hook";
import "@fortawesome/fontawesome-free/css/all.min.css";
import axios from "axios";
import * as _ from "lodash";
// import { Provider} from '@rollbar/react'; // Commented by @Vinit on 22/03/2023
import MaintenancePage from "MaintenancePage";
import React, { Suspense, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { createRoot } from "react-dom/client";
import "react-perfect-scrollbar/dist/css/styles.css";
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider } from "react-redux";
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import store from "stores/global-store";
import { ThemeProvider } from "styled-components";

import App from "./App";
import LanguageProvider from "./components/LanguageProvider";
import { GoogleOAuthProvider } from "@react-oauth/google";
import Spinner from "./components/Spinner";
import {
  GOOGLE_TAG_MANAGER_CUSTOM_DOMAIN,
  GOOGLE_TAG_MANAGER_ID,
} from "./constants";
import { SERVER_URL, GOOGLE_CLIENT_ID } from "./constants/index";
import AppProviders from "./context/AppProviders";
import { useAuth } from "./context/authContext";
import { IntercomChatProvider } from "./context/IntercomChatProvider";
import Start from "./Devices/Mobile/Pages/JobFlow/Steps/start";

import translations from "./i18n";
import * as serviceWorker from "./serviceWorker";
import ShabbatProvider from "context/shabbatContext";

const queryClient = new QueryClient();

const gtmParams = {
  id: GOOGLE_TAG_MANAGER_ID,
  customDomain: GOOGLE_TAG_MANAGER_CUSTOM_DOMAIN,
};

window._ = _;

const theme = {
  primary: "#464646",
  secondary: "#908d8d",
  light: "#eaeaea",
  bg: "#2F3F4C",
};

function checkTimeRange(currentTime, startHour, endHour) {
  const currentHour = currentTime.getHours();
  return currentHour >= startHour && currentHour < endHour;
}

function checkAsiaKolkataTimezone(userTimezone) {
  // console.log( "User timezone is :: ", userTimezone );
  return userTimezone === "Asia/Kolkata" || userTimezone === "Asia/Calcutta";
}

function isWeekday(currentTime) {
  const day = currentTime.getDay(); // 0 (Sunday) to 6 (Saturday)

  return day >= 1 && day <= 5; // Monday to Friday
}

const RootApp = () => {
  const rootLocation = useLocation();
  const { user } = useAuth();
  const [isBuildInProgress, setIsBuildInProgress] = useState(false);
  const [mobile, setMobile] = useState(false);
  const checkIfIncludePages = () => {
    return (
      rootLocation.pathname === "/" ||
      rootLocation.pathname === "/select-software" ||
      rootLocation.pathname === "/select-problem" ||
      rootLocation.pathname === "/select-description"
    );
  };

  useEffect(() => {
    const deviceIsMobile = checkIfIncludePages();

    // console.log("Is current device is Mobile ?? :: ", deviceIsMobile);

    if (deviceIsMobile) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  }, []);

  useEffect(() => {
    // Get the user's current timezone
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    // console.log("User timezone:", userTimezone);

    // Check if the timezone is Asia/Kolkata or Asia/Calcutta
    if (checkAsiaKolkataTimezone(userTimezone)) {
      // Get the current time in the user's timezone
      const currentTime = new Date();

      // Check if the current day is a weekday (Monday to Friday)
      if (isWeekday(currentTime)) {
        // Define the time range: 10 AM to 6 PM
        const startHour = 10;
        const endHour = 18;

        // Check if the current hour is within the desired range
        if (checkTimeRange(currentTime, startHour, endHour)) {
          // Make an API call to check if the build process is in progress
          axios
            .get(`${SERVER_URL}/api/check-build-status`)
            .then((response) => {
              // console.log( "response>>>>>>>>>>>.", response );
              setIsBuildInProgress(response?.data?.message);
            })
            .catch((error) => {
              console.error("Error checking build status:", error);
              setIsBuildInProgress(false);
            });
        } else {
          console.log("Not yet time to run the code.");
        }
      } else {
        console.log("It's a weekend, not running the code.");
      }
    } else {
      console.log("Timezone is not Asia/Kolkata or Asia/Calcutta.");
    }
  }, [isBuildInProgress]);

  if (isBuildInProgress) {
    return <MaintenancePage />;
  }

  return (
    <Provider store={store}>
      <Suspense fallback={<Spinner />}>
        <LanguageProvider messages={translations}>
          <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={theme}>
              <GTMProvider state={gtmParams}>
                <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
                  <AppProviders>
                    <ShabbatProvider>
                      <IntercomChatProvider user={user}>
                        {isMobile && !localStorage.getItem("mobile") ? (
                          <Start />
                        ) : (
                          <App />
                        )}
                      </IntercomChatProvider>
                    </ShabbatProvider>
                  </AppProviders>
                </GoogleOAuthProvider>
              </GTMProvider>
            </ThemeProvider>
          </QueryClientProvider>
        </LanguageProvider>
      </Suspense>
    </Provider>
  );
};

const root = createRoot(document.getElementById("root"));

if (root) {
  root.render(
    <Router>
      <RootApp />
    </Router>
  );
}

serviceWorker.register();
