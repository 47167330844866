import React, { useState, useEffect } from 'react';
import { Button, Modal, Checkbox, Form } from 'antd';
import { InfoCircleOutlined, CloseOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import shabbatImage from '../../assets/images/shabbat.png';
import { useShabbat } from 'context/shabbatContext';
import { useAuth } from 'context/authContext';
import * as CustomerApi from "../../api/customers.api";
import * as TechnicianApi from "../../api/technician.api";
import { openNotificationWithIcon } from 'utils';

const ShabbatBanner = () => {
  const { isShabbat, shabbatTimes, getRemainingTime } = useShabbat();
  const [showModal, setShowModal] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState('');
  const [loading, setLoading] = useState(false);
  const { user } = useAuth();
  const [form] = Form.useForm();

  useEffect(() => {
    if (!isShabbat) return;

    const timerInterval = setInterval(() => {
      setTimeRemaining(getRemainingTime());
    }, 1000);

    // Auto show modal on Shabbat start
    const lastShownDate = localStorage.getItem('shabbatModalLastShown');
    const today = new Date().toISOString().split('T')[0];
    if (lastShownDate !== today) {
      setShowModal(true);
      localStorage.setItem('shabbatModalLastShown', today);
    }

    return () => clearInterval(timerInterval);
  }, [isShabbat, getRemainingTime]);

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleReminderSubmit = async (values) => {
    console.log('Reminder values:', values);
    setLoading(true);
    try {
      if (user?.userType === 'customer') {
        await CustomerApi.updateCustomer(user?.customer.id, {
          needShabbatEndNotificationEmail: values.notifications.includes('email'),
          needShabbatStartNotificationSMS: values.notifications.includes('sms'),
        });
      } else {
        await TechnicianApi.updateTechnician(user?.technician.id, {
          needShabbosNotificationEmail: values.notifications.includes('email'),
          needShabbosNotificationSMS: values.notifications.includes('sms'),
        });
      }

      form.resetFields();
      openNotificationWithIcon('success', 'Reminder Set', 'We will notify you when we are back.');
    } catch (error) {
      openNotificationWithIcon('error', 'Error', 'Failed to set reminder preferences.');
    } finally {
      setLoading(false);
      setShowModal(false);
    }
  };

  if (!isShabbat) return null;

  return (
    <>
      <BannerWrapper>
        <BannerContent>
          <InfoSection>
            <InfoCircleOutlined style={{ color: '#FFF8E1', fontSize: 16, flexShrink: 0 }} />
            <BannerText>
              Geeker.co is <strong>currently paused</strong> for Shabbat until{' '}
              <strong>
                {shabbatTimes.endTimeFormatted.day}, {shabbatTimes.endTimeFormatted.time}
              </strong>{' '}
              EST. 🕯️🕯️ We reopen in <strong>{timeRemaining}</strong>.{' '}
              <LearnMoreButton
                type="primary"
                onClick={() => setShowModal(true)}
              >
                Find out why & more
              </LearnMoreButton>
            </BannerText>
          </InfoSection>
        </BannerContent>

        <StyledModal
          title={
            <div className="modal-title">
              <InfoCircleOutlined style={{ fontSize: 20 }} />
              <span>Taking Time to Rest</span>
            </div>
          }
          visible={showModal}
          onCancel={handleModalClose}
          width={700}
          centered
          maskClosable={true}
          footer={null}
          closeIcon={<CloseOutlined style={{ color: '#fff' }} />}
        >
          <ModalContent>
            <div className="subtitle">
              Hi, We're in <strong>Shabbat Mode</strong> until{' '}
              {shabbatTimes.endTimeFormatted.day} {shabbatTimes.endTimeFormatted.time} EST
            </div>

            <div className="quote">
              "Six days you shall labor and do all your work, but the seventh day is a sabbath for your God:
              you shall not do any work—you, your son or daughter, your male or female slave, or your cattle,
              or the stranger who is within your settlements". (Exodus 20:8-10)
            </div>

            <p>
              At Geeker.co, we believe in the universal value of taking a day each week to pause, recharge,
              reflect, and reconnect with what matters most. We take this day to disconnect from our work of
              creative output, and from our devices and spend this special time focusing on G-d, our family
              and ourselves.
            </p>

            <p>
              We believe that Observing the Shabbat is a testimony that G-d actively guides the entire world
              (including our finances) at every single moment.
            </p>

            <img src={shabbatImage} alt="Shabbat" style={{ width: '70%', borderRadius: 8, marginTop: 16 }} />

            <p>
              Thank you for understanding. Please come back soon, we're looking forward to helping you and
              will be ready to assist you in <strong> {timeRemaining} </strong>
            </p>

            <div className="reminder-section">
              <Form form={form} onFinish={handleReminderSubmit}>
                <ReminderCheckboxes>
                  <div>Would you like us to notify you when we're back?</div>
                  <Form.Item name="notifications">
                    <Checkbox.Group>
                      <Checkbox value="email">Email me</Checkbox>
                      <Checkbox value="sms">Send me a text</Checkbox>
                    </Checkbox.Group>
                  </Form.Item>
                  <Button type="primary" htmlType="submit" style={{ background: '#15b1b0', border: 'none', height: '45px' }} loading={loading}>
                    Remind me
                  </Button>
                </ReminderCheckboxes>
              </Form>
            </div>
            <VideoLinksSection>
              <div className="links-header">
                Want to learn more? Visit these links:
              </div>
              <div className="primary-links">
                <StyledLink href="https://www.chabad.org/library/article_cdo/aid/633659/jewish/What-Is-Shabbat.htm" target="_blank" rel="noopener noreferrer">What is Shabbat?</StyledLink>
                {' '}
                <StyledLink href="https://www.chabad.org/library/article_cdo/aid/4459066/jewish/What-Celebrating-Shabbat-Can-Do-For-Our-Mental-Wellness.htm" target="_blank" rel="noopener noreferrer">Shabbat and Mental Wellness</StyledLink>
              </div>
              <div className="additional-links">
                Add..
                <StyledLink href="https://www.youtube.com/watch?v=s01ZO13Jq5M" target="_blank" rel="noopener noreferrer">🎥 Shabbat Menu</StyledLink>
                , {' '}
                <StyledLink href="https://www.youtube.com/watch?v=YG0lbfGUpwU" target="_blank" rel="noopener noreferrer">Explained</StyledLink>
                , {' '}
                <StyledLink href="https://www.youtube.com/watch?v=FVho7sP1t3Y" target="_blank" rel="noopener noreferrer">more</StyledLink>
              </div>
            </VideoLinksSection>
          </ModalContent>
        </StyledModal>
      </BannerWrapper>
    </>
  );
};

const ModalContent = styled.div`
  .title {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
  }

  .subtitle {
    font-size: 18px;
    margin-bottom: 16px;
  }

  .quote {
    font-style: italic;
    padding: 16px;
    background: #f8f9fa;
    border-left: 4px solid #15b1b0;
    margin: 16px 0;
  }

  .reminder-section {
    margin-top: 24px;
    padding: 16px;
    background: #fafafa;
    border-radius: 8px;
  }

  .links-section {
    margin-top: 24px;
    
    .link-title {
      font-weight: 600;
      margin-bottom: 8px;
    }
    
    .links-grid {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      gap: 12px;
    }
  }
`;

const StyledLink = styled.a`
  color: #15b1b0;
  text-decoration: underline;
  
  &:hover {
    color: #006565;
  }
`;

const BannerText = styled.span`
  strong {
    font-weight: 600;
    color: #fff;
  }
  color: rgba(255, 255, 255, 0.9);
`;

const VideoLinksSection = styled.div`
  margin-top: 20px;
  ul {
    list-style: none;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 12px;
    
    li {
      margin-bottom: 8px;
      
      a {
        color: #15b1b0;
        text-decoration: underline;
        
        &:hover {
          color: #006565;
        }
      }
    }
  }
`;

const ReminderCheckboxes = styled.div`
  .ant-form-item {
    margin-bottom: 12px;
  }
  
  .ant-checkbox-wrapper {
    margin-right: 24px;
  }
`;

const BannerWrapper = styled.div`
  background: linear-gradient(122deg, #006565 0%, #15b1b0 100%);
  width: 100%;
  padding: 8px 24px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  position: relative;
`;

const BannerContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  
  @media (max-width: 768px) {
    flex-direction: row;
    justify-content: flex-start;
    padding: 4px 0;
  }
`;

const InfoSection = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  flex: 1;
  min-width: 0;
  
  @media (max-width: 768px) {
    justify-content: flex-start;
    margin-left: 4px;
  }
`;

const LearnMoreButton = styled(Button)`
  background: rgba(255, 248, 225, 0.2) !important;
  border: none !important;
  height: 28px !important;
  padding: 0 16px !important;
  margin-left: 8px !important;
  font-size: 14px !important;
  color: #ffffff !important;
  flex-shrink: 0;
  
  &:hover {
    background: rgba(255, 248, 225, 0.3) !important;
  }

  &:active {
    transform: scale(0.98);
    transition: transform 0.1s;
  }

  @media (max-width: 768px) {
    margin-left: 4px !important;
    padding: 0 8px !important;
  }
`;

const StyledModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 12px;
    overflow: hidden;
  }
  
  .ant-modal-header {
    padding: 16px 24px;
    border-bottom: none;
    background: #15b1b0;
    color: white;
  }
  
  .modal-title {
    display: flex !important;
    align-items: center !important;
    gap: 12px !important;
    font-size: 19px;
    font-weight: 700;
    color: white;
  }

  .ant-modal-title {
    color: white;
  }
  
  .ant-modal-body {
    padding: 24px;
  }
  
  .ant-modal-footer {
    border-top: none;
    padding: 0 24px 24px;
  }
  
  .modal-text {
    color: #2c3e50;
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 16px;
  }

  .modal-close-btn {
    background: #15b1b0 !important;
    &:hover {
      background: #006565 !important;
    }
    &:active {
      transform: scale(0.98);
    }
  }
`;

export default ShabbatBanner;