import React, { useState, useEffect } from "react";
import InputEmail from "./steps/InputEmail";
import Header from "./components/Header";
import ProgressBar from "./components/ProgressBar";
import Footer from "./components/Footer";
import SignUp from "./steps/SignUp";
import SignIn from "./steps/SignIn";
import PhoneNumber from "./steps/PhoneNumber";
import { useUser } from "../../../../context/useContext";
import { useLocation } from "react-router";
import AddCard from "./steps/AddCard";
import "./CSS/signUp.css";
import "./CSS/addCard.css";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { STRIPE_TEST_KEY, STRIPE_KEY, JOB_STATUS } from "../../../../constants";
import { isLiveUser } from "../../../../utils";
import { useFetchInvite } from "../../../../api/invite.api";
import Loader from "../../../../components/Loader";
import ScheduleJob from "./steps/ScheduleJob";
import ScheduleJobSummary from "./steps/ScheduleJobSummary";

const CustomerSignUp = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const jobIdFromUrl =
    queryParams.get("jobId") && queryParams.get("jobId") !== "false"
      ? queryParams.get("jobId")
      : false;
  const techIdFromUrl = queryParams.get("technicianId")
    ? queryParams.get("technicianId")
    : false;
  const page = queryParams.get("page") ? queryParams.get("page") : false;
  const inviteCode = queryParams.get("inviteCode") || "nothing";
  const { data: inviteData } = useFetchInvite(inviteCode);
  let liveUser;
  const { user, setUser, setToken } = useUser();
  const [userEmail, setUserEmail] = useState();
  const [mobileSignUpStepNumber, setMobileSignUpStepNumber] = useState(0);
  const [stripePromise, setStripePromise] = useState(loadStripe(STRIPE_KEY));
  const [userId, setUserId] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [selectedScheduleTime, setSelectedScheduleTime] = useState();
  const [reScheduleTime, setReScheduleTime] = useState(false);
  const signUpFlowStepsObj = {
    InputEmail: 0,
    SignUp: 1,
    SignIn: 2,
    AddCard: 3,
    PhoneNumber: 4,
    ScheduleJob: 5,
    ScheduleJobSummary: 6,
  };

  const isJobReShedule = queryParams.get("reschedule")
  ? queryParams.get("reschedule")
  : false;

  useEffect(() => {
    if (inviteData) {
      if (inviteData.status === "completed") {
        window.location.href = "/";
      } else {
        setIsLoading(false);
      }
    }
  }, [inviteData]);

  useEffect(() => {
    (async () => {
      if (user) {
        liveUser = await isLiveUser(user);
        let stripePromiseToSet = await loadStripe(
          liveUser ? STRIPE_KEY : STRIPE_TEST_KEY
        );
        if (stripePromiseToSet) {
          setStripePromise(stripePromiseToSet);
        }
        // setbusinessPlanStepNumber(jobFlowStepsObj["CompleteYourPurchase"])
      }

      if (inviteCode === "nothing") setIsLoading(false);

      if (inviteCode && inviteCode != "nothing") {
        localStorage.setItem("inviteCode", inviteCode);
        window.location.href = `mobile-sign-up?page=SignUp`;
      }

      // Checking if page name is given in url.
      if (page) {
        console.log("My console for page", page, typeof page);
      } else {
        if (jobIdFromUrl) {
          window.location.href = `mobile-sign-up?jobId=${jobIdFromUrl}&page=InputEmail`;
        }
      }
    })();
  }, []);

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center w-100percent">
        <Loader height="500px" />
      </div>
    );
  }

  return (
    <>
      <div className="d-flex flex-column justify-content-between align-items-center w-100percent overflow-hidden">
        <div className="w-100percent">
          <Header
            mobileSignUpStepNumber={mobileSignUpStepNumber}
            setMobileSignUpStepNumber={setMobileSignUpStepNumber}
            jobIdFromUrl={jobIdFromUrl}
            signUpFlowStepsObj={signUpFlowStepsObj}
            page={page}
            techIdFromUrl={techIdFromUrl}
          />
          {page !== "ScheduleJob" && (
            <ProgressBar
              mobileSignUpStepNumber={mobileSignUpStepNumber}
              page={page}
            />
          )}
          {page === "InputEmail" && (
            <InputEmail
              userEmail={userEmail}
              setUserEmail={setUserEmail}
              setMobileSignUpStepNumber={setMobileSignUpStepNumber}
              signUpFlowStepsObj={signUpFlowStepsObj}
              jobIdFromUrl={jobIdFromUrl}
              setToken={setToken}
              setUserId={setUserId}
              techIdFromUrl={techIdFromUrl}
            />
          )}
          {page === "SignUp" && (
            <SignUp
              userEmail={userEmail}
              setUserEmail={setUserEmail}
              setMobileSignUpStepNumber={setMobileSignUpStepNumber}
              signUpFlowStepsObj={signUpFlowStepsObj}
              setToken={setToken}
              jobIdFromUrl={jobIdFromUrl}
              setUserId={setUserId}
              techIdFromUrl={techIdFromUrl}
            />
          )}
          {page === "SignIn" && (
            <SignIn
              userEmail={userEmail}
              setUserEmail={setUserEmail}
              setMobileSignUpStepNumber={setMobileSignUpStepNumber}
              signUpFlowStepsObj={signUpFlowStepsObj}
              setToken={setToken}
              jobIdFromUrl={jobIdFromUrl}
              setUserId={setUserId}
              techIdFromUrl={techIdFromUrl}
            />
          )}
          {/* {stripePromise !== '' && <Elements stripe={stripePromise} > */}
          {page === "AddCard" && (
            <Elements stripe={stripePromise}>
              <AddCard
                user={user}
                userId={userId}
                jobIdFromUrl={jobIdFromUrl}
                setMobileSignUpStepNumber={setMobileSignUpStepNumber}
                signUpFlowStepsObj={signUpFlowStepsObj}
                techIdFromUrl={techIdFromUrl}
              />
            </Elements>
          )}
          {page === "PhoneNumber" && (
            <PhoneNumber
              jobIdFromUrl={jobIdFromUrl}
              setMobileSignUpStepNumber={setMobileSignUpStepNumber}
              userId={userId}
              techIdFromUrl={techIdFromUrl}
              user={user}
            />
          )}
          {page === "ScheduleJob" && (
            <ScheduleJob
              jobIdFromUrl={jobIdFromUrl}
              setMobileSignUpStepNumber={setMobileSignUpStepNumber}
              reScheduleTime={reScheduleTime}
              setReScheduleTime={setReScheduleTime}
              selectedScheduleTime={selectedScheduleTime}
              setSelectedScheduleTime={setSelectedScheduleTime}
              techIdFromUrl={techIdFromUrl}
              user={user}
              isJobReShedule={isJobReShedule}
            />
          )}
          {page === "ScheduleJobSummary" && (
            <ScheduleJobSummary jobIdFromUrl={jobIdFromUrl} />
          )}
        </div>
        <div className="w-100percent">
          <Footer
            mobileSignUpStepNumber={mobileSignUpStepNumber}
            setMobileSignUpStepNumber={setMobileSignUpStepNumber}
            reScheduleTime={reScheduleTime}
            setReScheduleTime={setReScheduleTime}
            selectedScheduleTime={selectedScheduleTime}
            setSelectedScheduleTime={setSelectedScheduleTime}
            page={page}
            user={user}
          />
        </div>
      </div>
    </>
  );
};

export default CustomerSignUp;
